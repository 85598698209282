<div class="builder-tuning-cost__item">
  <div class="builder-tuning-cost__group">
    <div
      class="builder-tuning-cost__group-name"
      [class.builder-tuning-cost__group-name_bold]="showCostFields() || builderDataService.addEditForm.get('activeRide.cancelled').value"
    >
      {{config().dictionary.totalCost | translate}}
    </div>
    <div class="builder-tuning-cost__group-field">
      <input
        uInput
        [uId]="'builder-tuning-total-cost'"
        [class]="'u-input_text-center'"
        [mask]="'0*.0*'"
        [dropSpecialCharacters]="false"
        [formControl]="builderDataService.addEditForm.get('activeRide.totalCost')"
        (click)="formControlClick.emit()"
      >
    </div>

    <div
      *ngIf="!builderDataService.addEditForm.get('activeRide.cancelled').value && builderDataService.customerIsOwnedBySc()"
      class="u-icon u-icon_pointer u-icon_app-builder-arrow-toggle builder-tuning-cost__group-arrow-toggle"
      [ngClass]="{
        'u-icon_app-builder-arrow-toggle-top': showCostFields(),
        'u-icon_app-builder-arrow-toggle-down': !showCostFields()
      }"
      (click)="showCostFieldsToggle(); formControlClick.emit()"
    ></div>
  </div>
</div>

<ng-container *ngIf="showCostFields()">
  <div class="builder-tuning-cost__item">
    <div class="builder-tuning-cost__group">
      <div class="builder-tuning-cost__group-name">
        {{config().dictionary.ridePrice | translate}}
      </div>
      <div class="builder-tuning-cost__group-double-fields">
        <div class="builder-tuning-cost__group-double-field">
          <u-select-s
            [uId]="'builder-tuning-cost-type'"
            [items]="builderTuningStoreService.costTypes()"
            [empty]="false"
            [formControl]="builderDataService.addEditForm.get('activeRide.costType')"
            [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [container]="'body'"
            (click)="formControlClick.emit()"
          ></u-select-s>
        </div>
        <div class="builder-tuning-cost__group-double-field">
          <input
            uInput
            [uId]="'builder-tuning-cost'"
            [mask]="'0*.0*'"
            [formControl]="builderDataService.addEditForm.get('activeRide.cost')"
            [valid]="builderDataService.isManualCostRequiredValid"
            [dropSpecialCharacters]="false"
            (click)="formControlClick.emit()"
          >
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="builderDataService.addEditForm.get('activeRide.chargeExtraFee').value !== null"
    class="builder-tuning-cost__item"
  >
    <div class="builder-tuning-cost__group">
      <div class="builder-tuning-cost__group-name">
        {{config().dictionary.extraFee | translate}}
      </div>
      <div class="builder-tuning-cost__group-double-fields">
        <div class="builder-tuning-cost__group-double-field">
          <u-toggle
            [uId]="'builder-tuning-cost-charge-extra-fee'"
            [formControl]="builderDataService.addEditForm.get('activeRide.chargeExtraFee')"
            (click)="formControlClick.emit()"
          ></u-toggle>
        </div>
        <div class="builder-tuning-cost__group-double-field">
          <input
            uInput
            [uId]="'builder-tuning-cost-extraFee'"
            [formControl]="builderDataService.addEditForm.get('activeRide.extraFee')"
            (click)="formControlClick.emit()"
          >
        </div>
      </div>
      <div
        class="builder-tuning-cost__info-icon"
        uTooltip="{{config().dictionary.extraFeeInfo | translate}}"
        [placement]="[ 'top', 'bottom' ]"
        [container]="'body'"
      ></div>
    </div>
  </div>
  <div class="builder-tuning-cost__item">
    <div class="builder-tuning-cost__group">
      <div class="builder-tuning-cost__group-name">
        {{config().dictionary.tollRoads | translate}}
      </div>
      <div class="builder-tuning-cost__group-double-fields">
        <div class="builder-tuning-cost__group-double-field">
          <u-select-s
            [uId]="'builder-tuning-cost-toll-roads-type'"
            [items]="config().tollRoadsCostTypesItems"
            [empty]="false"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [container]="'body'"
            [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
            [formControl]="builderDataService.addEditForm.get('activeRide.tollRoadsCostType')"
            (click)="formControlClick.emit()"
          ></u-select-s>
        </div>
        <div class="builder-tuning-cost__group-double-field">
          <input
            uInput
            [uId]="'builder-tuning-cost-toll-roads'"
            [mask]="'0*.0*'"
            [dropSpecialCharacters]="false"
            [formControl]="builderDataService.addEditForm.get('activeRide.tollRoadsCost')"
            (click)="formControlClick.emit()"
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
