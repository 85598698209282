<div class="header-main">
  <ng-container
    *ngIf="headerDataService.headerTemplate(); else header"
    [ngTemplateOutlet]="headerDataService.headerTemplate()"
  ></ng-container>

  <ng-template #header>
    <ng-container
      *ngIf="{
        mainMenuIcons: headerMenuIconsService.mainMenuIcons$ | async,
        collapsed: uSidebarMenuService.collapsed$ | async,
        showDate: headerDataService.showDate$ | async,
        actionMenuIcons: headerMenuIconsService.actionMenuIcons$ | async
      } as obsObj"
    >
      <div class="header-block">
        <u-menu-icons
          *ngIf="obsObj.mainMenuIcons.length && (headerMenuIconsService.showMenu$ | async)"
          class="header-block__icons"
          [isRtl]="isRtl"
          [icons]="obsObj.mainMenuIcons"
          (iconClick)="headerDataService.menuIconClick($event, true)"
          (iconDropdownClose)="headerMenuIconsService.unselectIcon($event)"
        ></u-menu-icons>
        <div class="header-block__search-wrapper">
          <u-search
            *ngIf="headerDataService.showGlobalSearch"
            [isRtl]="isRtl"
            class="u-search_app-header"
            [class.u-search_app-header_menu-collapsed]="obsObj.collapsed"
            [config]="headerSearchFiltersService.uSearchConfigValue$ | async"
            [showFiltersDrawer]="headerSearchFiltersService.showHeaderFiltersDrawer$ | async"
            (applySearch)="headerSearchFiltersService.applySearch($event)"
            (applyFilters)="headerSearchFiltersService.onFiltersApplied($event)"
            (filterIconClick)="headerSearchFiltersService.toggleShowFiltersDrawer()"
          ></u-search>
        </div>
      </div>

      <div class="header-block">
        <button
          *ngIf="nextYearDataService.isInNextYearMode && obsObj.showDate"
          uButton
          uId="header-next-year-mode-indicator"
          class="header__next-year-mode-indicator"
          [view]="uButtonView.Flat"
          [size]="uButtonSize.Small"
          (click)="nextYearDataService.leaveNextYear()"
        >
          {{config.dictionary.nextYearPlan | translate}}
        </button>
        <u-menu-icons
          *ngIf="obsObj.actionMenuIcons.length"
          [class]="'u-menu-icons_app-header header-main__action-icons'"
          [isRtl]="isRtl"
          [icons]="obsObj.actionMenuIcons"
          (iconClick)="headerDataService.menuIconClick($event)"
        ></u-menu-icons>
        <app-header-demands-view
          *ngIf="demandsStore.showViewToggle()"
          class="header-main__demands-view"
        ></app-header-demands-view>
        <app-header-routes-view-type
          *ngIf="headerDataService.showRoutesViewType$ | async"
          class="header-main__routes-view-type"
        ></app-header-routes-view-type>
        <div
          uId="header-date"
          class="header-date"
          [ngClass]="{ 'header-date_disabled': headerDataService.isDateDisabled }"
        >
          <ng-container *ngIf="obsObj.showDate">
            <button
              uButton
              uId="header-today-button"
              class="header-date__switch"
              [view]="uButtonView.Flat"
              [size]="uButtonSize.Small"
              [disabled]="!(showWeekSwitch ? showThisWeekButton : showTodayButton)"
              (click)="showWeekSwitch ? backToThisWeek() : backToday()"
            >
              {{(showWeekSwitch ? config.dictionary.thisWeek : config.dictionary.today) | translate}}
            </button>
            <div
              class="header-date__day"
              uId="header-date-day"
              *ngIf="!showWeekSwitch"
            >
              <div class="header-date__day-box">
                <div class="header-date__day-calendar">
                  <u-input-date
                    class="u-input-date_app-header"
                    [mode]="'pick'"
                    [showArrows]="true"
                    [formControl]="headerDataService.dateForm"
                    [lang]="lang"
                    [format]="appConstants[isShiftNYEnv ? 'DATE_FORMAT_BASE_AMERICAN' : 'DATE_FORMAT_BASE_SLASH_SHORT']"
                    [valid]="headerDataService.dateForm.valid"
                    [placement]="isRtl ? ['bottom-left'] : ['bottom-right']"
                    [readonly]="true"
                    [defaultUncheckDate]="true"
                    [messages]="'uInputDate' | translate"
                    [datePickerFooterTemplate]="nextYearDataService.nextYearAvailable && isNextYearAvailable && nextYear"
                    [idPrefix]="'header'"
                  ></u-input-date>
                </div>
              </div>
            </div>
            <div class="header-date__week" *ngIf="showWeekSwitch">
              <div class="header-date__week-box">
                <app-week-switch
                  class="header-date__week-calendar week-switch_app-header"
                  [initDate]="headerDataService.weekSwitch.initDate"
                  [startDateLimit]="headerDataService.weekSwitch.startDateLimit"
                  [endDateLimit]="headerDataService.weekSwitch.endDateLimit"
                  [datePickerRangeFooterTemplate]="nextYearDataService.nextYearAvailable && isNextYearAvailable && nextYear"
                  (weekChange)="headerDataService.weekSwitchChangeSet($event)"
                ></app-week-switch>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="commonService.showMonitoringDashboard$ | async"
          class="header-monitoring-dashboard"
          [ngClass]="{ 'header-monitoring-dashboard_full-width': obsObj.collapsed }"
        >
          <app-monitoring-dashboard
            [activeRideId]="headerDataService.dashboardDetails.activeRideId"
            (closeAll)="headerDataService.closeDashboard()"
          ></app-monitoring-dashboard>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

<ng-template #nextYear>
  <div
    class="header-next-year"
    (click)="toggleNextYearMode()"
  >
    <div class="header-next-year__icon"></div>
    <button
      uButton
      [size]="uButtonSize.Small"
    >
      {{config.dictionary[nextYearDataService.isInNextYearMode ? 'nextYearPlan' : 'nextYear'] | translate}}
    </button>
  </div>
</ng-template>
