import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment';

import { RoutesChangeDataAction } from '@app/routes/models';
import { VisibleComponent, VisibleComponents, HeaderDashboard } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private previousFullUrl: BehaviorSubject<string> = new BehaviorSubject(null);
  private visibleComponents: BehaviorSubject<VisibleComponents> = new BehaviorSubject({
    [VisibleComponent.Feed]: false,
    [VisibleComponent.Notes]: false,
    [VisibleComponent.RouteNotes]: false,
    [VisibleComponent.Activities]: false,
    [VisibleComponent.MonitoringDashboard]: false,
    [VisibleComponent.ImportContract]: false,
    [VisibleComponent.PassengersImport]: false,
    [VisibleComponent.MovePassengers]: false,
    [VisibleComponent.ReOptimization]: false
  });

  showFeedOnLoad: boolean = true;
  showReOptimizationOnLoad: boolean = false;

  rideOpenDashboard: Subject<HeaderDashboard> = new Subject();
  previousUrl: BehaviorSubject<string> = new BehaviorSubject('/');
  openNextYearPanelClicked: Subject<any> = new Subject();
  changedRouteData: Subject<RoutesChangeDataAction> = new Subject();
  visibleComponents$ = this.visibleComponents.asObservable();
  showNotes$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.Notes]), distinctUntilChanged());
  showActivities$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.Activities]), distinctUntilChanged());
  showRouteNotes$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.RouteNotes]), distinctUntilChanged());
  showFeed$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.Feed]), distinctUntilChanged());
  showMonitoringDashboard$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.MonitoringDashboard]), distinctUntilChanged());
  showImportContract$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.ImportContract]), distinctUntilChanged());
  showPassengersImport$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.PassengersImport]), distinctUntilChanged());
  showMovePassengers$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.MovePassengers]), distinctUntilChanged());
  showReOptimization$ = this.visibleComponents$.pipe(map(components => components[VisibleComponent.ReOptimization]), distinctUntilChanged());

  updateVisibleComponent(component: VisibleComponent, value: boolean, hideAll: boolean = false) {
    this.visibleComponents.next({
      ...this.visibleComponents.value,
      [component]: value,
      ...(value || hideAll ?
        Object.keys(this.visibleComponents.value).reduce((acc, key) => ({
          ...acc,
          [key]: key === component ? value : false
        }), {}) : {}
      ) as VisibleComponents
    });
  }

  updateChangedRouteData(data: RoutesChangeDataAction) {
    this.changedRouteData.next(data);
  }

  setRideOpenDashboard(data: HeaderDashboard) {
    this.rideOpenDashboard.next(data);
  }

  nextYearIconClick() {
    this.openNextYearPanelClicked.next();
  }

  getPreviousUrl() {
    return this.previousUrl.getValue();
  }

  setPreviousUrl(data: string) {
    this.previousUrl.next(data);
  }

  getPreviousFullUrl() {
    return this.previousFullUrl.getValue();
  }

  updatePreviousFullUrl(value: string) {
    this.previousFullUrl.next(value);
  }

  generateWeekDates(startDate: string, endDateTime: string): Date[] {
    const datesRange: Date[] = [];
    const isNegativeTimezone: boolean = new Date().getTimezoneOffset() > 0;
    const endWeekDate = moment(endDateTime).add(isNegativeTimezone ? 1 : 0, 'day').toDate();
    let startWeekDate = new Date(moment(endDateTime).startOf('week').toDate());

    while (startWeekDate <= endWeekDate) {
      datesRange.push(startWeekDate);
      startWeekDate = moment(startWeekDate).add(1, 'day').toDate();
    }

    return datesRange;
  }

  getVisibleComponentsValue(): VisibleComponents {
    return this.visibleComponents.value;
  }

  updateShowFeedOnLoad(value: boolean) {
    this.showFeedOnLoad = value;
  }

  updateShowReOptimizationOnLoad(value: boolean) {
    this.showReOptimizationOnLoad = value;
  }
}
