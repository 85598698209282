import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { cloneDeep, remove, isEqual, max } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, take, takeUntil, tap, filter, withLatestFrom } from 'rxjs/operators';
import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import {
  UChip,
  UGridScroll,
  UGridSort,
  UInputCitiesCityLocation,
  UPopoverDirective,
  UPopupService,
  USidebarMenuService,
  UThreeDotsPopoverItem,
  UTooltipDirective
} from '@shift/ulib';

import {
  ApprovalsConfigService,
  CommonService,
  LocalizationService,
  HeaderSearchFiltersService,
  TrackingService,
  LoadingDataService,
  HeaderMenuIconsService,
  GridHeaderService,
  HeaderDataService
} from '@app/shared/services';
import {
  AppLanguage,
  HeaderMenuIconValue,
  VisibleComponent,
  WindowResize,
  GridHeaderButtonValue
} from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { appConfig, gridConfig } from '@app/shared/configs';
import { RouteLockState } from '@app/route-lock-state/models';
import { RouteLockStateService } from '@app/route-lock-state/services';
import {
  AuthCustomer,
  AuthCustomerType,
  AuthModuleRouteBuilderFeatureType,
  AuthModuleRoutesTableFeatureColumn,
  AuthModuleRoutesTableFeatureType,
  AuthOptionalModule,
  AuthUserCustomerRoleType
} from '@app/auth/models';
import { ActivitiesDataService } from '@app/activities/services';
import { FileSaverService } from '@app/files/services';
import { BuilderRoute, BuilderRouteStatus } from '@app/builder/models';
import { BuilderCommonService, BuilderRoutesStoreService } from '@app/builder/services';
import { FeedFilterService } from '@app/feed/services';
import { FeedFilter, FeedStatus } from '@app/feed/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { RouteSplitComponent } from '@app/route-split/components';
import { KpiService } from '@app/kpi/services';
import { Kpi } from '@app/kpi/models';
import {
  RideStationEntrance,
  RideStationEntranceStatus,
  RideTimeType,
  RideTypesPerChanges,
  RoutesChangeViewType,
  RoutesChangeOptions,
  RouteDailyItem,
  RouteDailyRideStatus,
  RouteDailyRow,
  RouteDailyRowProp,
  RouteDailyYitCtrlStatus,
  RoutesDailyParams,
  RoutesMovePassengersRouteInitData,
  RoutesThreeDotsDeleteAction,
  RoutesViewTypeMode,
  RoutesDailyColumn,
  RoutesExportType,
  RouteDirection,
  RouteSaveStatus,
  RoutesInitalData,
  RoutesRidePassengerDetails,
  RoutesRidePassengersCustomer,
  RoutesExportAgendaExcelParams,
  RoutesThreeDotsPopoverItemAction
} from '@app/routes/models';
import {
  RoutesCancelRideModalService,
  RoutesCommonService,
  RoutesDailyMovePassengersDataService,
  RoutesRestoreRideModalService,
  RoutesTempCommentModalService,
  RoutesExportModalService,
  RoutesTableService,
  RoutesDataService
} from '@app/routes/services';
import { RoutesFacade } from '@app/routes/state/facades';
import { RideApprovalsService } from '@app/ride-approvals/services';
import { RideDriverApproval } from '@app/ride-approvals/models';
import { routesConfig, routesDailyMovePassengersConfig } from '@app/routes/configs';
import { RoutesMovePassengersComponent } from '../routes-move-passengers/routes-move-passengers.component';
import { RoutesDailyFineComponent } from './routes-daily-fine/routes-daily-fine.component';
import { routesDailyComponentConfig } from './routes-daily.component.config';

@Component({
  selector: 'app-routes-daily',
  templateUrl: './routes-daily.component.html',
  styleUrls: [ './routes-daily.component.scss', './routes-daily.component.rtl.scss' ],
  providers: [ LoadingDataService, RoutesRestoreRideModalService, RoutesCancelRideModalService, RoutesTempCommentModalService, AuthDataSnapshotService ]
})
export class RoutesDailyComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('activitiesColumn', { static: true }) public activitiesColumn: TemplateRef<any>;
  @ViewChild('notesColumn', { static: true }) public notesColumn: TemplateRef<any>;
  @ViewChild('startHourColumn', { static: true }) public routeStartHourColumn: TemplateRef<any>;
  @ViewChild('startHourColumnFilter', { static: true }) public routeStartHourColumnFilter: TemplateRef<any>;
  @ViewChild('endHourColumn', { static: true }) public routeEndHourColumn: TemplateRef<any>;
  @ViewChild('endHourColumnFilter', { static: true }) public routeEndHourColumnFilter: TemplateRef<any>;
  @ViewChild('codeColumn', { static: true }) public routeCodeColumn: TemplateRef<any>;
  @ViewChild('directionColumn', { static: true }) public routeDirectionColumn: TemplateRef<any>;
  @ViewChild('directionFilter', { static: true }) public routeDirectionFilter: TemplateRef<any>;
  @ViewChild('accompanyColumn', { static: true }) public routeAccompanyColumn: TemplateRef<any>;
  @ViewChild('accompanyCostCell', { static: true }) public accompanyCostCell: TemplateRef<any>;
  @ViewChild('shuttleCompanyColumn', { static: true }) public routeShuttleCompanyColumn: TemplateRef<any>;
  @ViewChild('driverColumn', { static: true }) public routeDriverColumn: TemplateRef<any>;
  @ViewChild('carCell', { static: true }) public carCell: TemplateRef<any>;
  @ViewChild('passengersAmountColumn', { static: true }) public routeTotalPassengersColumn: TemplateRef<any>;
  @ViewChild('customersColumn', { static: true }) public customersColumn: TemplateRef<any>;
  @ViewChild('timerColumn', { static: true }) public routeTimerColumn: TemplateRef<any>;
  @ViewChild('yitStatusCell', { static: true }) public yitStatusCell: TemplateRef<any>;
  @ViewChild('ctrlStatusCell', { static: true }) public ctrlStatusCell: TemplateRef<any>;
  @ViewChild('routesDailyTable', { static: true }) routesDailyTable: ElementRef<HTMLElement>;
  @ViewChild('routeMonitoringStatus', { static: true }) routeMonitoringStatus: TemplateRef<any>;
  @ViewChild('carTypes', { static: true }) carTypes: TemplateRef<any>;
  @ViewChild('statusColumnFilter', { static: true }) public statusColumnFilter: TemplateRef<any>;
  @ViewChild('supervisorCell', { static: true }) public supervisorCell: TemplateRef<any>;
  @ViewChild('supervisorCellFilter', { static: true }) public supervisorCellFilter: TemplateRef<any>;
  @ViewChild('profitLossCell', { static: true }) public profitLossCell: TemplateRef<any>;
  @ViewChild('profitLossPercentCell', { static: true }) public profitLossPercentCell: TemplateRef<any>;
  @ViewChild('profitLossPercentCellFilter', { static: true }) public profitLossPercentCellFilter: TemplateRef<any>;
  @ViewChild('timeTrackingCell', { static: true }) public timeTrackingCell: TemplateRef<any>;
  @ViewChild('createdByCell', { static: true }) public createdByCell: TemplateRef<any>;

  @Input() initialData: RoutesInitalData;
  @Input() refreshTableData: any;
  @Input() clearDailyCheckedItems: any;
  @Input() tableFilterType: any;
  @Input() feedFilter: FeedFilter;
  @Input() resetColumnsFilter: boolean;
  @Input() closeAllPopovers: boolean;
  @Input() highlightRoutes: number[] = [];
  @Input() showMovePassengers: boolean;
  @Input() showReOptimization: boolean;
  @Input() reOptimizationRouteIds: number[];

  @Output() visibleRoutesAmountChanged: EventEmitter<any> = new EventEmitter();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() totalKeysChanged: EventEmitter<any> = new EventEmitter();
  @Output() checkedRoutes: EventEmitter<any> = new EventEmitter();
  @Output() resetColumnsFilterAction: EventEmitter<any> = new EventEmitter();
  @Output() columnsFilteredAction: EventEmitter<any> = new EventEmitter();
  @Output() deleteRouteAction: EventEmitter<RoutesThreeDotsDeleteAction> = new EventEmitter();
  @Output() openRouteNotes: EventEmitter<RouteDailyRow> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-daily';

  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly bsModalService = inject(BsModalService);
  private readonly translateService = inject(TranslateService);
  private readonly uPopupService = inject(UPopupService);
  private readonly gridHeaderService = inject(GridHeaderService);
  private readonly authDataService = inject(AuthDataService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly approvalsConfigService = inject(ApprovalsConfigService);
  private readonly loadingDataService = inject(LoadingDataService);
  private readonly trackingService = inject(TrackingService);
  private readonly fileSaverService = inject(FileSaverService);
  private readonly localizationService = inject(LocalizationService);
  private readonly routesExportModalService = inject(RoutesExportModalService);
  private readonly feedFilterService = inject(FeedFilterService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderCommonService = inject(BuilderCommonService);
  private readonly activitiesDataService = inject(ActivitiesDataService);
  private readonly kpiService = inject(KpiService);
  private readonly routeLockStateService = inject(RouteLockStateService);
  private readonly routesFacade = inject(RoutesFacade);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesRestoreRideModalService = inject(RoutesRestoreRideModalService);
  private readonly routesCancelRideModalService = inject(RoutesCancelRideModalService);
  private readonly routesTempCommentModalService = inject(RoutesTempCommentModalService);
  private readonly routesDataService = inject(RoutesDataService);
  private readonly rideApprovalsService = inject(RideApprovalsService);
  public readonly document = inject(DOCUMENT);
  public readonly uSidebarMenuService = inject(USidebarMenuService);
  public readonly commonService = inject(CommonService);
  public readonly headerDataService = inject(HeaderDataService);
  public readonly headerMenuIconsService = inject(HeaderMenuIconsService);
  public readonly routesCommonService = inject(RoutesCommonService);
  public readonly routesDailyMovePassengersDataService = inject(RoutesDailyMovePassengersDataService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  readonly #isMovePassengers = signal(false);

  readonly isMovePassengers = this.#isMovePassengers.asReadonly();
  readonly isEditRoutes = computed(() => this.authDataSnapshotService.editRoutes() && !this.#isMovePassengers());

  private userId: number;
  private tableOffsetY: number;
  private showRouteEndsInColumn: boolean;
  private unsubscribe: Subject<void> = new Subject();
  private movePassengersModalRef: BsModalRef;
  private tableSorts: UGridSort[];
  private costFilters: { withPrice: string; withoutPrice: string; } = {
    withPrice: null,
    withoutPrice: null
  };
  private withoutContractCodeFilter: string;
  private withoutRegulationNumberFilter: string;
  private commentsFilters: { withComments: string; withoutComments: string; };
  private timeTrackingFilters: { withReport: string; withoutReport: string; };
  private filteredRows = [];
  private routesArrayCopy = [];
  private changesPopover: UPopoverDirective;
  private lockedByTooltipIdsClosed: string[] = [];
  private costTypes: { [key: string]: string } = {};
  private scrollToRouteId: number;
  private routeIdToEdit: number;
  private placeholders: { [key: string]: string; };
  private translations: { [key: string]: string | { [key: string]: string; }; };

  config = routesDailyComponentConfig;
  customerId: number;
  direction = RouteDirection;
  routesChangeViewType = RoutesChangeViewType;
  selectedRoutesChangeViewType: RoutesChangeViewType;
  stationEntranceEnum = RideStationEntrance;
  typesPerChanges = RideTypesPerChanges;

  daysToRoute: number;
  activeRide: RouteDailyRow;
  popoversStore: {
    [RouteDailyRowProp.PassengersCustomers]: RoutesRidePassengersCustomer[];
    [RouteDailyRowProp.PassengerDetails]: RoutesRidePassengerDetails[];
    [RouteDailyRowProp.Drivers]: RideDriverApproval[];
  } = {
      [RouteDailyRowProp.PassengersCustomers]: null,
      [RouteDailyRowProp.PassengerDetails]: null,
      [RouteDailyRowProp.Drivers]: null
    };

  routes: RouteDailyRow[] = [];
  columns: RoutesDailyColumn[] = [];
  visibleColumns = [];
  selectedRows: RouteDailyRow[] = [];
  specificRowClassObjects = [];

  threeDotsMenu: UThreeDotsPopoverItem[];
  threeDotsMenuActive: UThreeDotsPopoverItem[];
  threeDotsMenuCanceled: UThreeDotsPopoverItem[];
  isToday: boolean = true;
  needsDriverApprove: boolean;
  authCustomer: AuthCustomer;
  changesApprovalsEnabled: boolean;
  isSCCustomer: boolean;
  showBackdrop: boolean;
  requiredRecalculation: boolean;
  routeBuilderFeatureType: AuthModuleRouteBuilderFeatureType;
  routeBuilderFeatureTypeShuttleCompany: boolean;
  routesTableFeatureTypeShuttleCompany: boolean;

  lang: AppLanguage = this.localizationService.getLanguage();
  timerFormatFutureRoute = AppConstants.TIME_FORMAT;
  routeDailyRowProp = RouteDailyRowProp;

  arrivedAt: string;
  notAssigned: string;
  driverRequired: string;
  statusCanceled: string;
  statusNotActive: string;
  gotToDestinationOnTime: string;
  gotToDestinationWithLate: string;
  gotToDestinationArrivedLate: string;
  routeStatuses: { [key: string]: string };
  entranceToPickUpArea: { [key: string]: string };

  tableName = 'routes-daily';
  viewportElement: HTMLElement;
  changeOptions: RoutesChangeOptions;
  changeOptionStartTime: RoutesChangeOptions = {
    timeType: RideTimeType.StartTime
  };
  changeOptionEndTime: RoutesChangeOptions = {
    timeType: RideTimeType.EndTime
  };
  isRtl: boolean = this.localizationService.isRtl();
  routeSaveStatus = RouteSaveStatus;
  routeLockState: RouteLockState;
  appConstants = AppConstants;
  routesDailyMovePassengersConfig = routesDailyMovePassengersConfig;
  gridConfig = gridConfig;

  showKpi: boolean;
  routesConfig = routesConfig;
  showPassengersInfo: boolean;
  allowPassengersChange: boolean;
  allowShuttleCompanyChange: boolean;
  timeTrackingChangesPopover: UPopoverDirective;
  timeTrackingPopover: { popover: UPopoverDirective; trackingName: string; data: RouteDailyRow; };
  scrollToRowIndex: number;

  rowIdentity: Function = row => row.routeId;

  readonly #kpi: WritableSignal<Kpi> = signal(null);

  readonly kpi = this.#kpi.asReadonly();

  ngOnInit() {
    this.initTranslations();
    this.translate();
    this.onChangedRoute();

    this.headerMenuIconsService.menuIconClick$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ value }) => {
        switch (value) {
          case HeaderMenuIconValue.Refresh: {
            if (this.showKpi) {
              this.getKpi();
            }

            break;
          }

          case HeaderMenuIconValue.MovePassengers: {
            this.track('Global header - click on Move passengers icon');

            this.commonService.updateVisibleComponent(
              VisibleComponent.MovePassengers,
              !this.commonService.visibleComponents()[VisibleComponent.MovePassengers]
            );

            break;
          }
        }
      });

    this.gridHeaderService.buttonClick$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(button => {
        switch (button.value) {
          case GridHeaderButtonValue.DailyAgendaExcel: {
            this.exportDailyAgendaExcelClick();

            break;
          }

          case GridHeaderButtonValue.RouteDetailsExcel: {
            this.exportRouteDetailsClick();

            break;
          }
        }
      });

    this.updateSpecificRowClassObjects([]);

    this.refreshTableData = false;
    this.clearDailyCheckedItems = false;
    this.checkedRoutes.emit({ routes: [] });

    this.builderCommonService.lastEditedRouteId$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(routeId => {
        if (routeId) {
          this.scrollToRouteId = routeId;
        }
      });

    this.feedFilterService.filteringEnabledEvent
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.routes) {
          this.filterRoutesByFeedFilter();
        }
      });

    this.onDailyLoad();

    this.routesFacade.dailyInit();
    this.routesFacade.dailyItemsInit(this.getRoutesParams());
  }

  ngOnChanges(changes: SimpleChanges) {
    const refreshed = (this.refreshTableData === true);

    if (changes && changes.clearDailyCheckedItems && changes.clearDailyCheckedItems.currentValue === true) {
      this.selectedRows = [];
      this.checkedRoutes.emit({ routes: [] });
    }

    if (refreshed) {
      this.loadingDataService.updateRefresh();

      if (this.highlightRoutes && this.highlightRoutes.length) {
        this.removeHighlightRoute(this.highlightRoutes);
      }

      this.refresh.emit();
    }

    if (changes.tableFilterType) {
      this.loadingDataService.updateRefresh();
      this.showBackdrop = false;
    }

    if (changes.feedFilter && this.routesArrayCopy.length) {
      this.filterRoutesByFeedFilter();
      this.showBackdrop = false;
    }

    if (
      changes.feedFilter &&
      changes.feedFilter.currentValue &&
      changes.feedFilter.previousValue &&
      !changes.feedFilter.currentValue.value &&
      changes.feedFilter.previousValue.value &&
      !changes.feedFilter.firstChange
    ) {
      this.routes = this.routesArrayCopy;
      this.visibleRoutesAmountChanged.emit(this.routes.length);

      if (this.showMovePassengers) {
        this.routesDailyMovePassengersDataService.updateAvailableRoutes(this.routes);
      }
    }

    if (changes.highlightRoutes && this.highlightRoutes) {
      this.selectedRows = [];
      this.checkedRoutes.emit({ routes: [] });
      this.highlightRoutes.forEach((routeId: number) => {
        this.highlightRoute(routeId);
      });
    }

    if (changes.closeAllPopovers && this.closeAllPopovers) {
      if (this.changesPopover && this.changesPopover.isOpen()) {
        this.changesPopover.close();
      }
    }

    if (changes.showMovePassengers && typeof this.showMovePassengers === 'boolean') {
      this.#isMovePassengers.set(this.showMovePassengers);

      this.updateMovePassengersQueue();

      this.routesDailyMovePassengersDataService.updateAvailableRoutes(this.routes);
    }

    if (changes.showReOptimization) {
      this.columns = this.columns
        .map(col => col.prop === 'notes' || col.prop === 'activities' ?
          { ...col, hideColumn: this.showReOptimization } : col);
    }

    if (changes.initialData && this.initialData) {
      this.initialData?.costTypes.forEach(type => {
        this.costTypes[type.id] = type.name;
      });

      this.updateDailyItemsRoutes(this.routesArrayCopy);
    }

    if (changes.reOptimizationRouteIds && this.showReOptimization) {
      this.routes = this.routesArrayCopy.filter(route => this.reOptimizationRouteIds.includes(route.routeId));
    }
  }

  ngAfterViewInit() {
    this.setViewportElement();
  }

  ngOnDestroy() {
    this.routesFacade.dailyLoadCancel();

    if (this.router.url.replace('/', '') !== this.config.routeBuilderUrl) {
      this.builderCommonService.lastEditedRouteIdSet(null);
    }

    this.commonService.updateVisibleComponent(VisibleComponent.MovePassengers, false);

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private onChangedRoute() {
    this.routesCommonService.changedRoute$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.closeRoutesChange());
  }

  private initTranslations() {
    this.translations = {
      yitCtrl: Object.entries(this.config.dictionary.yitCtrl).reduce((acc, [ key, value ]) => ({ ...acc, [key]: this.translateService.instant(value) }), {}),
      direction: this.translateService.instant(this.config.dictionary.direction),
      emptyValue: this.translateService.instant(this.config.dictionary.emptyValue),
      automaticProcess: this.translateService.instant(this.config.dictionary.automaticProcess)
    };
  }

  private timeTrackingDateTimeFilterTypeItemsFn = () => [ this.timeTrackingFilters.withReport, this.timeTrackingFilters.withoutReport ];

  private directionColumnFilter = items => items.map(item =>
    item === RouteDirection.Forward ?
      { value: item, name: this.translations.direction['forward'] } : { value: item, name: this.translations.direction['backward'] }
  );

  private createdByColumnFilterSorting = items => items.map(item => ({ value: item, name: item || this.translations.automaticProcess }));

  private yitCtrlStatusColumnFilter = items => {
    const yitStatuses = {
      null: this.translations.emptyValue,
      [RouteDailyYitCtrlStatus.NotSent]: this.translations.yitCtrl['notSent'],
      [RouteDailyYitCtrlStatus.Sent]: this.translations.yitCtrl['sent'],
      [RouteDailyYitCtrlStatus.SendingFailed]: this.translations.yitCtrl['sendingFailed'],
      [RouteDailyYitCtrlStatus.DriverOrVehicleAssigned]: this.translations.yitCtrl['driverOrVehicleAssigned'],
      [RouteDailyYitCtrlStatus.FailedToAssignDriverOrCar]: this.translations.yitCtrl['failedToAssignDriverOrCar']
    };

    return items.map(item => ({ value: item, name: yitStatuses[item] }));
  };

  private timeTrackingFilter = (value, searchValue) => {
    if (searchValue === this.timeTrackingFilters.withReport) {
      return !!value;
    }

    if (searchValue === this.timeTrackingFilters.withoutReport) {
      return !value;
    }

    return true;
  };

  private bidNumberFilterTypeItemsSearchFn = (value, searchValue) => searchValue === this.withoutContractCodeFilter ? !value : value === searchValue;

  private regulationNumberFilterTypeItemsSearchFn = (value, searchValue) => searchValue === this.withoutRegulationNumberFilter ? !value : value === searchValue;

  private commentsFilterTypeItemsFn = () => [ this.commentsFilters.withComments, this.commentsFilters.withoutComments ];

  private commentsFilterTypeItemsSearchFn = (value, searchValue) => {
    if (searchValue === this.commentsFilters.withComments) {
      return !!value;
    }

    if (searchValue === this.commentsFilters.withoutComments) {
      return !value;
    }

    return true;
  };

  private sortRideDateTime = (dateTimePrev: string, dateTimeNext: string): number => {
    if (moment(dateTimePrev).isBefore(moment(dateTimeNext))) { return -1; }
    if (moment(dateTimePrev).isAfter(moment(dateTimeNext))) { return 1; }
  };

  private displayShuttleCompanyFilterSortingFunction = items => items.sort(
    (prev, curr) => prev === this.placeholders.required ? -1 : prev.localeCompare(curr)
  );

  private costFilterTypeItemsFn = () => [ this.costFilters.withPrice, this.costFilters.withoutPrice ];

  private costFilterTypeItemsSearchFn = (value, searchValue) => {
    if (searchValue === this.costFilters.withPrice) {
      return !!value;
    }

    if (searchValue === this.costFilters.withoutPrice) {
      return !value;
    }

    return true;
  };

  private sortCarTypes = (typePrev: any, typeNext: any, rowPrev, rowNext) => {
    const carTypePrev = !rowPrev.carType ? { seatsCount: 0, name: '' } : rowPrev.carType;
    const carTypeNext = !rowNext.carType ? { seatsCount: 0, name: '' } : rowNext.carType;

    if (carTypePrev.seatsCount === carTypeNext.seatsCount) {
      return carTypePrev.name < carTypeNext.name ? -1 : 1;
    }

    return carTypePrev.seatsCount < carTypeNext.seatsCount ? -1 : 1;
  };

  private track(message: string) {
    this.trackingService.track(`[${routesConfig.trackingId}] - ${message}`);
  }

  private updateMovePassengersQueue() {
    if (!this.showMovePassengers) {
      this.enableUnavailableRoutes();

      return;
    }

    this.removeUnavailableSelectedRows();
    this.disableUnavailableRoutes();

    this.routesDailyMovePassengersDataService.updateRoutesQueue(
      cloneDeep(this.selectedRows),
      this.routesDailyMovePassengersDataService.isQueueEmpty
    );
  }

  private removeUnavailableSelectedRows() {
    this.selectedRows = this.selectedRows.filter(row =>
      this.routes.find(route => route.routeId === row.routeId && !route.cancelled && !route.locked && !route.isFullManualRide && route.active)
    );
  }

  private disableUnavailableRoutes() {
    this.routes = this.routes.map(route => (!route.locked && (route.cancelled || route.isFullManualRide || !route.active) ? {
      ...route,
      locked: true,
      disabled: true
    } : route));
  }

  private enableUnavailableRoutes() {
    this.routes = this.routes.map(route => (route.disabled && (route.cancelled || route.isFullManualRide || !route.active) ? {
      ...route,
      locked: false,
      disabled: false
    } : route));
  }

  private onDailyLoad() {
    this.routesFacade.dailyItems$
      .pipe(
        filter(data => !!data),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.onHeaderSearchFilters());

    this.routesFacade.dailyLoadSuccess$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.loadingDataService.updateLoading(false);
        this.getApprovalsState();

        if (this.routeIdToEdit) {
          this.editRoute(this.routes.find(route => route.routeId === this.routeIdToEdit));

          this.routeIdToEdit = null;
        }
      });
  }

  private onHeaderSearchFilters() {
    let inited = false;

    combineLatest([
      this.loadingDataService.refresh$,
      this.headerSearchFiltersService.appliedSearch$.pipe(tap(() => this.trackingService.track('[Tracks] - search'))),
      this.headerSearchFiltersService.appliedFilters$.pipe(tap(() => this.track('filter'))),
      this.headerDataService.date$
        .pipe(
          tap(() => {
            if (this.showKpi) {
              this.getKpi();
            }

            if (this.commonService.visibleComponents()[VisibleComponent.MovePassengers]) {
              this.selectRoutes({ selected: [] });

              this.routesDailyMovePassengersDataService.initialize();
            }
          })
        )
    ])
      .pipe(
        debounceTime(100),
        map(() => this.getRoutesParams()),
        withLatestFrom(this.routesFacade.dailyParams$),
        filter(([ next, prev ]) => {
          if (!inited) {
            inited = true;

            return !isEqual(next, prev);
          }

          return !isEqual(next, prev) || !this.loadingDataService.getLoadingValue();
        }),
        map(([ next ]) => next),
        takeUntil(this.unsubscribe)
      )
      .subscribe(params => {
        const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO);

        this.isToday = moment().isSame(activeDate, 'd');
        this.daysToRoute = !this.isToday ? activeDate.diff(moment(), 'd') + 1 : null;

        this.loadingDataService.updateLoading(true);
        this.routesFacade.dailyLoad(params);
      });
  }

  private exportDailyAgendaExcelClick() {
    this.trackingService.track('[Tracks] - excel export');
    this.exportDailyAgendaExcel();
  }

  private exportRouteDetailsClick() {
    this.trackingService.track(`[${this.config.headerTrackingId}] - 3 dot menu- click on Route details Excel`);

    const routeIds = (this.selectedRows.length ? this.selectedRows : []).map(row => row.routeId);

    this.routesExportModalService.openModal(routeIds, RoutesExportType.Excel);
  }

  private translate() {
    this.translateService.get('routes.daily')
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => {
        this.routeStatuses = res.routeStatuses;
        this.statusCanceled = res.routeStatuses.cancelled;
        this.statusNotActive = res.routeStatuses.notActive;
        this.entranceToPickUpArea = res.routeStatuses.entranceToPickUpArea;

        this.placeholders = res.placeholders;
        this.notAssigned = res.placeholders.notAssigned;
        this.driverRequired = res.placeholders.driverRequired;

        this.gotToDestinationWithLate = res.routeStatuses.gotToDestination.hasLated;
        this.gotToDestinationArrivedLate = res.routeStatuses.gotToDestination.arrivedLate;
        this.gotToDestinationOnTime = res.routeStatuses.gotToDestination.hasArrivedOnTime;
        this.arrivedAt = res.routeStatuses.gotToDestination.arrivedAt;

        this.costFilters = {
          withPrice: res.tableFilters.withPrice,
          withoutPrice: res.tableFilters.withoutPrice
        };

        this.commentsFilters = {
          withComments: res.tableFilters.withComments,
          withoutComments: res.tableFilters.withoutComments
        };

        this.timeTrackingFilters = {
          withReport: res.tableFilters.reported,
          withoutReport: res.tableFilters.withoutReport
        };

        this.withoutContractCodeFilter = res.tableFilters.withoutContractCode;
        this.withoutRegulationNumberFilter = res.tableFilters.withoutRegulationNumber;

        this.initAuthUserInfo();
      });

    this.onDailyItemsChange();
  }

  private scrollToSavedRoute() {
    if (this.scrollToRouteId) {
      setTimeout(() => this.scrollToRowIndex = this.routes.findIndex(route => route.routeId === this.scrollToRouteId));

      this.highlightRoute(this.scrollToRouteId);

      setTimeout(() => this.updateSpecificRowClassObjects([]), 3000);
    }
  }

  private initAuthUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(userData => {
        this.authCustomer = userData.customer;
        this.userId = userData.person.memberId;
        this.customerId = this.authCustomer.customerId;
        this.isSCCustomer = this.authCustomer.type === AuthCustomerType.ShuttleCompany;

        const modules = userData.modules;
        const passengers = modules?.passengers;
        const routesTable = modules?.routesTable;
        const routesTablePassengersInfo = !!routesTable?.passengersInfo;

        this.showKpi = !!modules?.kpi;
        this.showPassengersInfo = this.isSCCustomer ? routesTablePassengersInfo : !!passengers && routesTablePassengersInfo;
        this.showRouteEndsInColumn = !!routesTable?.columns?.includes(AuthModuleRoutesTableFeatureColumn.RouteEndsInDays);
        this.allowPassengersChange = !!routesTable?.passengerChange;
        this.allowShuttleCompanyChange = !!routesTable?.shuttleCompanyChange;
        this.routeBuilderFeatureType = modules?.routeBuilder?.type;
        this.routeBuilderFeatureTypeShuttleCompany = this.routeBuilderFeatureType === AuthModuleRouteBuilderFeatureType.ShuttleCompany;
        this.routesTableFeatureTypeShuttleCompany = routesTable.type === AuthModuleRoutesTableFeatureType.ShuttleCompany;

        const threeDotsMenu = routesConfig.threeDotsMenu[routesTable.type] || routesConfig.threeDotsMenu.default;

        this.threeDotsMenu = this.routesDataService.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenu);
        this.threeDotsMenuActive = this.routesDataService.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenuActive);
        this.threeDotsMenuCanceled = this.routesDataService.getFilteredThreeDotsMenu(threeDotsMenu.threeDotsMenuCanceled);

        this.initTableColumns(userData.optionalModules);
      });
  }

  private initTableColumns(optionalModules: AuthOptionalModule[]) {
    this.columns = this.config.columns.reduce((acc, column: RoutesDailyColumn) => {
      if (this.checkColumnAvailability(column, optionalModules)) {
        return [
          ...acc,
          {
            ...column,
            ...(column.cellTemplateName ? { cellTemplate: this[column.cellTemplateName] } : {}),
            ...(column.filterTemplateName ? { filterTemplate: this[column.filterTemplateName] } : {}),
            ...(column.filterTypeItemsFnName ? { filterTypeItemsFn: this[column.filterTypeItemsFnName] } : {}),
            ...(column.filterSortingFunctionName ? { filterSortingFunction: this[column.filterSortingFunctionName] } : {}),
            ...(column.comparatorName ? { comparator: this[column.comparatorName] } : {}),
            ...(column.filterTypeItemsSearchFnName ? { filterTypeItemsSearchFn: this[column.filterTypeItemsSearchFnName] } : {}),
            ...(column.filterEmptyValueName ? { filterEmptyValue: this[column.filterEmptyValueName] } : {}),
            ...(column.initialVisibilityFeature ? { hideColumn: !this.authDataService.checkFeature(column.initialVisibilityFeature) } : {}),
            ...(this.isRtl ? {
              ...(column.maxWidthRtl ? { maxWidth: column.maxWidthRtl } : {}),
              ...(column.minWidthRtl ? { minWidth: column.minWidthRtl } : {})
            } : {})
          }
        ];
      }

      return acc;
    }, [] as RoutesDailyColumn[]);
  }

  private checkColumnAvailability(column: RoutesDailyColumn, optionalModules: AuthOptionalModule[]) {
    return (!column.feature || this.authDataService.checkFeature(column.feature)) &&
      (!column.customerType || column.customerType === this.authCustomer.type) &&
      (!column.exceptCustomerType || column.exceptCustomerType !== this.authCustomer.type) &&
      (!column.optionalModule || optionalModules.includes(column.optionalModule));
  }

  private checkRequiredRecalculationAndUpdateStatusColumn(routes: RouteDailyRow[]) {
    const requiredRecalculation = routes.some(route => route.requiredRecalculation);

    if (requiredRecalculation !== this.requiredRecalculation) {
      this.columns = this.columns.map(column => column.requiredRecalculationWidth || column.requiredRecalculationMinWidth ? ({
        ...column,
        width: requiredRecalculation ? column.requiredRecalculationWidth : column.width,
        minWidth: requiredRecalculation ? column.requiredRecalculationMinWidth : column.minWidth
      }) : column);

      this.requiredRecalculation = requiredRecalculation;
    }
  }

  private updateDailyItemsRoutes(routes: RouteDailyItem[]) {
    this.totalKeysChanged.emit(routes.length);

    this.routes = (routes || []).map(route => this.parseRoute(route));

    this.routesArrayCopy = cloneDeep(this.routes);

    if (this.showReOptimization && this.reOptimizationRouteIds) {
      this.routes = this.routes.filter(route => this.reOptimizationRouteIds.includes(route.routeId));
    }

    if (this.showMovePassengers) {
      this.routesDailyMovePassengersDataService.updateAvailableRoutes(this.routes);

      this.disableUnavailableRoutes();
    }

    this.scrollToSavedRoute();
    this.checkRequiredRecalculationAndUpdateStatusColumn(this.routes);

    this.visibleRoutesAmountChanged.emit(this.routes.length);

    if (this.feedFilterService.filteringEnabled) {
      this.filterRoutesByFeedFilter();
    }
  }

  private onDailyItemsChange() {
    this.routesFacade.dailyItems$
      .pipe(
        takeUntil(this.unsubscribe),
        map(data => data || [])
      )
      .subscribe(routes => this.updateDailyItemsRoutes(routes));
  }

  private parseRoute(route: RouteDailyItem): RouteDailyRow {
    let threeDotsMenuItems = route.cancelled && route.totalPassengers === 0
      ? this.threeDotsMenu : route.cancelled && route.totalPassengers !== 0
        ? this.threeDotsMenuCanceled : this.threeDotsMenuActive;

    const isOwnedBySc = !this.routeBuilderFeatureTypeShuttleCompany || route.isOwnedBySc;

    if (moment.duration(moment(route.routeEndDate).diff(moment(route.routeStartDate))).asDays() > 1) {
      threeDotsMenuItems = threeDotsMenuItems.filter(item => item.action !== RoutesThreeDotsPopoverItemAction.SplitRoute);
    }

    if (route.isFullManualRide) {
      threeDotsMenuItems = threeDotsMenuItems.filter(item => item.action !== RoutesThreeDotsPopoverItemAction.MovePassengers);
    }

    if (!isOwnedBySc) {
      threeDotsMenuItems = threeDotsMenuItems.filter(item => !routesConfig.threeDotsMenuOwnedByScActions.includes(<RoutesThreeDotsPopoverItemAction>item.action));
    }

    const newRoute: RouteDailyRow = {
      ...route,
      threeDotsMenuItems,
      distance: parseFloat(route.distance.toFixed(2)),
      dateCountShow: route.dateCountShow || false,
      saveStatus: route.saveStatus || null,
      lockState: route.lockState || null,
      displayShuttleCompany: null,
      displayAccompanyName: null,
      cost: route.shuttleCompany && route.shuttleCompany.cost,
      costType: this.costTypes[route.shuttleCompany && route.shuttleCompany.costType],
      duration: route.duration && moment.duration(route.duration).format(AppConstants.TIME_FORMAT_MOMENT_MIN),
      timeTrackingDuration: route.timeTrackingDuration && moment.duration(route.timeTrackingDuration).format(AppConstants.TIME_FORMAT_MOMENT_MIN),
      executionCost: route.subShuttleCompany?.executionCost ?? route.shuttleCompany?.executionCost
    };

    if (moment().startOf('day').isAfter(moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day'))) {
      if (newRoute.rideStatus !== RouteDailyRideStatus.Cancelled &&
        newRoute.rideStatus !== RouteDailyRideStatus.FinishedMonitored &&
        newRoute.rideStatus !== RouteDailyRideStatus.Finished
      ) {
        newRoute.rideStatus = RouteDailyRideStatus.Finished;
      }
    }

    if (this.showRouteEndsInColumn) {
      const dateEnd = moment(newRoute.routeEndDate);
      const dateNow = moment();
      const dateDays = moment.duration(dateEnd.diff(dateNow)).asDays();

      newRoute.daysCount = Math.ceil(dateDays);

      if (newRoute.daysCount <= routesConfig.daysOfWeek.length) {
        newRoute.dateCountShow = true;
      }
    }

    if (newRoute.carType && newRoute.carType.name) {
      newRoute.carTypeName = newRoute.carType.name;
    }

    newRoute.isActive = false;
    newRoute.gotToDestinationHour = null;
    newRoute.routeStartTime = moment(newRoute.rideStartDateTime, AppConstants.DATE_FORMAT_ISO);
    newRoute.monitoringStatus = this.defineRideMonitoringStatus(newRoute);
    newRoute.isStartTimePast = this.daysToRoute < 0;
    newRoute.routeCanceled = false;
    newRoute.hideButtons = false;
    newRoute.status = newRoute.rideStatus ? `routes.daily.routeStatuses.${newRoute.rideStatus.charAt(0).toLowerCase() + newRoute.rideStatus.slice(1)}` : '';
    newRoute.displayShuttleCompany = this.displayShuttleName(newRoute);
    newRoute.displayAccompanyName = this.displayAccompanyName(newRoute);

    return newRoute;
  }

  private getKpi() {
    this.kpiService.getAll(this.headerDataService.getDate())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(kpi => this.#kpi.set(kpi));
  }

  private setViewportElement(sizes: WindowResize = {
    width: window.innerWidth,
    height: window.innerHeight
  }) {
    const { width, height } = sizes;

    this.viewportElement = width >= 1600 && height >= 800 ? this.routesDailyTable.nativeElement.querySelector('datatable-body') : this.document.body;
  }

  private getRoutesParams(): RoutesDailyParams {
    const filters = this.headerSearchFiltersService.getAppliedFiltersValue();
    let params: RoutesDailyParams = {
      date: this.headerDataService.getDate(),
      direction: RouteDirection.BackwardAndForward,
      filterType: this.tableFilterType.value,
      searchText: this.headerSearchFiltersService.getSearchValue(),
      schoolIds: <number[]>filters.schools || [],
      classTypeIds: <number[]>filters.classes || [],
      passengerIds: <number[]>filters.passengers || [],
      departmentIds: <number[]>filters.departments || [],
      branchIds: <number[]>filters.branches || [],
      shiftIds: <number[]>filters.shifts || [],
      tagIds: <number[]>filters.tags || [],
      cities: <UInputCitiesCityLocation[]>filters.citiesAreas || [],
      bidNumbers: <string[]>filters.bidNumbers || [],
      masterSubCustomerIds: <number[]>filters.subCustomers || []
    };

    if (this.showReOptimization) {
      params = {
        ...params,
        searchText: '',
        schoolIds: [],
        classTypeIds: [],
        passengerIds: [],
        departmentIds: [],
        branchIds: [],
        shiftIds: [],
        tagIds: [],
        cities: [],
        bidNumbers: [],
        masterSubCustomerIds: []
      };
    }

    return params;
  }

  private displayAccompanyName(row: RouteDailyRow): string {
    if (row.accompany) {
      return row.accompany.name || 'general.anonymousAccompany';
    }

    return row.needAccompany ? 'routes.daily.placeholders.required' : 'routes.daily.placeholders.without';
  }

  private getApprovalsState(): void {
    this.approvalsConfigService.getApprovalsConfig()
      .subscribe(res => {
        if (res) {
          if (res.changesApprovalsEnabled) {
            this.changesApprovalsEnabled = res.changesApprovalsEnabled;
          }

          if (res.approvalTypes) {
            this.needsDriverApprove = res.approvalTypes.includes(AuthUserCustomerRoleType.Driver);
          }
        }
      });
  }

  onFilterRows(data: any) {
    this.filteredRows = data;
    this.visibleRoutesAmountChanged.emit(data.length);
  }

  windowResize(sizes: WindowResize) {
    this.setViewportElement(sizes);
  }

  onCellClick(data: RouteDailyRow, type: RoutesChangeViewType, popover: UPopoverDirective, options?: RoutesChangeOptions) {
    if (type === RoutesChangeViewType.Supervisor) {
      this.track('click on supervisor');
    }

    if (type === RoutesChangeViewType.Hour) {
      this.changeOptions = options;
    }

    this.showBackdrop = true;
    this.changesPopover = null;
    this.changesPopover = popover;

    const popoverSub = this.changesPopover.hidden.subscribe(() => {
      this.showBackdrop = false;
      popoverSub.unsubscribe();
    });

    this.activeRide = data;
    this.selectedRoutesChangeViewType = type;

    this.markRouteAsActive(data, true);
  }

  onHoursChangeCellClick(data: RouteDailyRow, type: RoutesChangeViewType, popover: UPopoverDirective, options?: RoutesChangeOptions) {
    if (data.isFullManualRide) {
      this.uPopupService.showMessage({
        message: this.config.dictionary.disabledChange,
        yes: this.config.dictionary.ok
      }, () => {});
    } else {
      this.onCellClick(data, type, popover, options);
    }
  }

  editRoute(activeRoute: any): void {
    this.markRouteAsActive(activeRoute, true);

    const routes: BuilderRoute[] = this.filteredRows.map(obj => ({
      status: BuilderRouteStatus.None,
      routeId: obj.routeId,
      code: obj.code,
      name: obj.name,
      direction: obj.direction,
      days: obj.days,
      startDate: obj.routeStartDate,
      endDate: obj.routeEndDate,
      rideStartDateTime: moment(obj.rideStartDateTime).format(AppConstants.TIME_FORMAT),
      rideEndDateTime: moment(obj.rideEndDateTime).format(AppConstants.TIME_FORMAT),
      totalStations: obj.totalStations,
      totalPassengers: obj.totalPassengers,
      carTypeName: obj.carType ? obj.carType.name : null,
      carTypeCapacity: obj.carType ? obj.carType.seatsCount : null,
      shuttleCompany: obj.shuttleCompany ? obj.shuttleCompany.name : null,
      locked: obj.locked
    }));

    let localStorageTableProps = JSON.parse(localStorage.getItem(appConfig.storageKeys.usersTablesProps)) || {};

    if (Object.keys(localStorageTableProps).length > 0) {
      localStorageTableProps = localStorageTableProps[this.userId] && localStorageTableProps[this.userId][this.tableName];
    }

    this.builderRoutesStoreService.updateGridProps({
      columnsOrder: localStorageTableProps && localStorageTableProps.columnsOrder,
      columnsStoreVisible: localStorageTableProps && localStorageTableProps.columnsStoreVisible,
      sorts: this.tableSorts,
      offsetY: this.tableOffsetY,
      hiddenColumns: [ 'days' ]
    });

    this.builderCommonService.editRouteIdSet(activeRoute.routeId);
    this.builderCommonService.lastEditedRouteIdSet(activeRoute.routeId);
    this.builderRoutesStoreService.routesSet(routes);

    this.router.navigateByUrl(this.config.routeBuilderUrl);
  }

  defineRideMonitoringStatus(row: any, isClass: boolean = false): string {
    const status = row.rideStatus;
    if (status) {
      switch (status) {
        case ('New'):
          return isClass ? 'ride-status_future-route' : this.routeStatuses.future;
        case ('Canceled'):
          return isClass ? 'ride-status_canceled' : this.routeStatuses.cancelled;
        case ('FinishedMonitored'):
          this.markRouteAsFinished(row);
          return isClass ? 'ride-status_finished-monitored' : this.routeStatuses.finishedMonitored;
        case ('Finished'):
          this.markRouteAsFinished(row);
          return  isClass ? 'ride-status_finished' : this.routeStatuses.finished;
        case ('OngoingMonitored'):
          return isClass ? 'ride-status_on-going-monitored' : this.routeStatuses.ongoingMonitored;
        case ('Ongoing'):
          return  isClass ? 'ride-status_on-going' : this.routeStatuses.ongoing;
        default:
          return isClass ? 'ride-status_future-route' : this.routeStatuses.future;
      }
    }
  }

  private markRouteAsFinished(row: any): void {
    row.isFinished = true;
    this.mapObjectToRowClass(row, 'isFinished', 'route-status-finished-row-highlight');
  }

  private markRouteAsActive(row: RouteDailyRow, isActive: boolean): void {
    if (row.isActive) {
      row.isActive = isActive;
      this.mapObjectToRowClass(row, 'isActive', 'route-is-active-row-highlight');
    }
  }

  private mapObjectToRowClass(row: RouteDailyRow, rowPropName: string, rowClassName: string): void {
    const containsSameRouteId = this.specificRowClassObjects.some(c => c.rowRouteId === row.routeId);
    const containsSameProperty = this.specificRowClassObjects.some(c => c.rowPropertyName === rowPropName);

    if (!(containsSameRouteId && containsSameProperty)) {
      const parameters = {
        rowRouteId: row.routeId,
        className: rowClassName,
        rowPropertyName: rowPropName,
        value: true
      };

      this.updateSpecificRowClassObjects([
        ...this.specificRowClassObjects,
        parameters
      ]);
    }
  }

  selectRoutes(data) {
    if (this.showMovePassengers) {
      this.routesDailyMovePassengersDataService.updateRoutesQueue(cloneDeep(data.selected));
    }

    this.selectedRows = data.selected;
    this.checkedRoutes.emit({ routes: data.selected });
  }

  private exportDailyAgendaExcel(): void {
    const resColumns = [];

    this.translateService.get('routes.daily').subscribe((res => {
      if (res) {
        const lang = this.localizationService.getLanguage();
        const fileName = res.export.fileName + ' ' + moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).locale( lang + '-IL' ).format( 'LL' );

        this.visibleColumns
          .filter(column => column.prop !== '' && column.prop !== 'check' && column.prop !== 'filter' && column.prop !== 'activities' && column.prop !== 'notes')
          .forEach(column => {
            const propName = column.exportPropName ? column.exportPropName : column.prop;

            if (propName.includes('.')) {
              const propNames = propName.split('.');
              resColumns.push({ field: propNames[0], alias: res.tableColumns[propNames[0]] });
            } else {
              resColumns.push({ field: propName, alias: res.tableColumns[column.exportPropNameAlias || propName] });
            }
          });

        resColumns.splice(1, 0, { field: 'isMonitored', alias: res.tableColumns['isMonitored'] });

        const routesExportAgendaExcelParams: RoutesExportAgendaExcelParams = {
          schoolIds: [],
          classTypeIds: [],
          passengerIds: [],
          departmentIds: [],
          branchIds: [],
          shiftIds: [],
          tagIds: [],
          cities: [],
          bidNumbers: [],
          masterSubCustomerIds: [],
          date: null,
          searchText: '',
          direction: null,
          filterType: null,
          ...this.getRoutesParams(),
          routeIds: this.selectedRows.map(row => row.routeId),
          columns: resColumns
        };

        this.routesTableService.exportAgendaToExcel(routesExportAgendaExcelParams)
          .subscribe(data => this.fileSaverService.downloadBlobFile(data, fileName + '.xlsx'));
      }
    }));
  }

  private displayShuttleName(row: RouteDailyRow): string {
    let result = row.shuttleCompany ? row.shuttleCompany.name : this.placeholders.required;

    if (this.showSubShuttleCompanyName(row)) {
      result = row.subShuttleCompany.name;
    }

    return result;
  }

  private showSubShuttleCompanyName(row: RouteDailyRow): Boolean {
    return row.shuttleCompany && row.subShuttleCompany
      && (this.customerId === row.shuttleCompany.value || this.customerId === row.subShuttleCompany.value);
  }

  openDashboard(data: RouteDailyRow): void {
    this.trackingService.track('[Tracks] - Click on status to open dashboard');

    this.uSidebarMenuService.updateCollapsed(true);

    this.headerDataService.openDashboard({
      rideId: data.rideId
    });
  }

  handleEntranceToPickUpAreas(stationEntranceStatus: RideStationEntranceStatus): string {
    if (!stationEntranceStatus.actualArriveDateTime && stationEntranceStatus.stationEntrance !== this.stationEntranceEnum.never) {
      return;
    }

    return stationEntranceStatus.stationEntrance === this.stationEntranceEnum.never
      ? this.entranceToPickUpArea.never
      : `${this.entranceToPickUpArea.startedFrom} ${moment(stationEntranceStatus.actualArriveDateTime).format(AppConstants.TIME_FORMAT)}`;
  }

  private filterRoutesByFeedFilter() {
    if (this.feedFilter && this.feedFilter.value) {
      this.routes = cloneDeep(
        this.routesArrayCopy.filter(route =>
          this.feedFilter?.items
            .includes(
              [
                FeedStatus.DuplicatedAccompany,
                FeedStatus.DuplicatedDriver,
                FeedStatus.DuplicatedSupervisor,
                FeedStatus.DuplicatedPassenger,
                FeedStatus.WithoutSupervisor,
                FeedStatus.RidesToReOptimize,
                FeedStatus.RidesWithEmptyStations
              ].includes(this.feedFilter.value) ? route.routeId : route.rideId
            )
        )
      );

      this.visibleRoutesAmountChanged.emit(this.routes.length);

      if (this.showMovePassengers) {
        this.routesDailyMovePassengersDataService.updateAvailableRoutes(this.routes);
      }
    }
  }

  private openFinePopup(data: RouteDailyRow) {
    this.bsModalService.show(
      RoutesDailyFineComponent,
      {
        class: 'u-modal u-modal_app-routes-daily-fine',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          data
        }
      }
    );
  }

  showWarnings(route: RouteDailyRow, type: RideTypesPerChanges): boolean {
    switch (type) {
      case this.typesPerChanges.StartHour: {
        if (!route.plannedRideStartDateTime) { return false; }

        const planedStartHour = moment(route.plannedRideStartDateTime).valueOf();
        const startHour = moment(route.rideStartDateTime).valueOf();

        return planedStartHour !== startHour;
      }

      case this.typesPerChanges.EndHour: {
        if (!route.plannedRideEndDateTime) { return false; }

        const planedEndHour = moment(route.plannedRideEndDateTime).valueOf();
        const endHour = moment(route.rideEndDateTime).valueOf();

        return planedEndHour !== endHour;
      }

      case this.typesPerChanges.Accompany: {
        const { plannedAccompany, accompany } = route;

        return this.checkWarnings(plannedAccompany, accompany);
      }
    }
  }

  private checkWarnings(planned, unPlanned): boolean {
    if (!planned && unPlanned || planned && !unPlanned) {
      return true;
    } else if (!planned && !unPlanned) {
      return false;
    }

    if (planned && unPlanned) {
      return planned.value !== unPlanned.value;
    }
  }

  carChangeDisabled(row) {
    const allowCarChange = (row.isSelfShuttleCompany
      || (this.isSCCustomer && row.shuttleCompany && row.shuttleCompany.value === this.customerId)
      || (this.isSCCustomer && row.subShuttleCompany && row.subShuttleCompany.value === this.customerId));

    return !allowCarChange;
  }

  closeRoutesChange() {
    this.showBackdrop = false;

    if (this.changesPopover.isOpen()) {
      this.changesPopover.close();
    }
  }

  columnsFilteredChange(data: boolean) {
    this.columnsFilteredAction.emit(data);
  }

  resetColumnsFilterChange(resetColumnsFilter: boolean) {
    this.resetColumnsFilterAction.emit(resetColumnsFilter);
  }

  private highlightRoute(id: number): void {
    const params = {
      className: 'highlighted-row',
      rowPropertyName: 'routeId',
      value: id
    };

    this.updateSpecificRowClassObjects([
      ...this.specificRowClassObjects,
      params
    ]);
  }

  private removeHighlightRoute(routes: number[]) {
    routes.forEach((routeId: number) => {
      const index = this.specificRowClassObjects.findIndex(ob => ob.value === routeId);

      if (index >= 0) {
        remove(this.specificRowClassObjects, (ob: any, i: number) => i === index);

        this.updateSpecificRowClassObjects([ ...this.specificRowClassObjects ]);
      }
    });
  }

  private openMovePassengersModal(data: RouteDailyRow): void {
    const activeDate: string = this.headerDataService.getDate();
    const route: RoutesMovePassengersRouteInitData = {
      routeId: data.routeId,
      code: data.code,
      name: data.name,
      direction: data.direction,
      routeStartDate: data.routeStartDate,
      routeEndDate: data.routeEndDate,
      days: data.days,
      activeDate,
      activeDay: moment(activeDate).day()
    };

    this.movePassengersModalRef = this.bsModalService.show(
      RoutesMovePassengersComponent,
      {
        class: `u-modal u-modal_content ${this.uSidebarMenuService.getCollapsedValue() ? 'u-modal_content-hide-menu ' : ''}u-modal_app-routes-daily-move-passengers`,
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          sourceRoute: route,
          authCustomer: this.authCustomer
        }
      }
    );

    this.movePassengersModalRef
      .content
      .saved
      .pipe(take(1))
      .subscribe(() => this.loadingDataService.updateRefresh());
  }

  private updateSpecificRowClassObjects(specificRowClassObjects) {
    this.specificRowClassObjects = specificRowClassObjects;
  }

  private updateDropListsOnScroll() {
    if (this.showMovePassengers) {
      setTimeout(
        () => this.routesDailyMovePassengersDataService.updateDropLists(),
        routesDailyMovePassengersConfig.updateDropListDelay
      );
    }
  }

  private openSplitRouteModal(route: RouteDailyRow) {
    this.bsModalService.show(
      RouteSplitComponent,
      {
        class: 'u-modal u-modal_app-routes-daily-route-split',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          route: {
            id: route.routeId,
            direction: route.direction,
            allowEmptyStations: route.allowEmptyStations
          },
          trackingId: routesConfig.trackingId,
          viewportElement: this.viewportElement
        }
      }
    )
      .content
      .splitAction
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(routeIds => {
        this.routeIdToEdit = max(routeIds);
        this.loadingDataService.updateRefresh();
      });
  }

  selectDotsItemAction(action: string, route: RouteDailyRow): void {
    const { routeId, routeStartDate, routeEndDate, days, code, name, isOwnedBySc, rideStartDateTime, totalPassengers, active } = route;

    switch (action) {
      case RoutesThreeDotsPopoverItemAction.DeleteRoute: {
        this.deleteRouteAction.emit({ routeId, activeRoute: route, mode: RoutesViewTypeMode.DailyView });

        break;
      }

      case RoutesThreeDotsPopoverItemAction.CancelRide: {
        this.routesCancelRideModalService.openModal({
          routeId,
          routeStartDate,
          routeEndDate,
          days,
          code,
          name,
          isOwnedBySc,
          rideStartDateTime
        });

        break;
      }

      case RoutesThreeDotsPopoverItemAction.RestoreRide: {
        this.routesRestoreRideModalService.openModal({
          routeId,
          routeStartDate,
          routeEndDate,
          days,
          code,
          name,
          isOwnedBySc,
          totalPassengers
        });

        break;
      }

      case RoutesThreeDotsPopoverItemAction.NewFine: {
        this.openFinePopup(route);

        break;
      }

      case RoutesThreeDotsPopoverItemAction.MovePassengers: {
        this.openMovePassengersModal(route);

        break;
      }

      case RoutesThreeDotsPopoverItemAction.TempComment: {
        this.routesTempCommentModalService.openModal(
          {
            routeId,
            code,
            name,
            active
          },
          this.viewportElement);

        break;
      }

      case RoutesThreeDotsPopoverItemAction.CreateRouteTemplate: {
        this.trackingService.track(`[${routesConfig.trackingId}} > 3 dot menu] - click on create template from route`);
        this.routesCommonService.createRouteTemplate(routeId, this.headerDataService.getDate());

        break;
      }

      case RoutesThreeDotsPopoverItemAction.SplitRoute: {
        this.trackingService.track(`[${routesConfig.trackingId}}] 3 dots - click on Split route`);
        this.openSplitRouteModal(route);

        break;
      }
    }
  }

  onScrollTable(scrollPosition: UGridScroll) {
    this.tableOffsetY = scrollPosition.offsetY;

    this.updateDropListsOnScroll();
  }

  onSortTable(sorts: UGridSort[]) {
    this.tableSorts = sorts;
  }

  openRouteActivities(routeId: number) {
    this.commonService.updateVisibleComponent(VisibleComponent.Activities, true);

    this.activitiesDataService.loadActivities({
      ...this.headerDataService.getDateRange(),
      routeId
    });

    this.trackingService.track('[Route table, Daily] - Click on activity icon');
  }

  onPopoverOpen(
    row: RouteDailyRow,
    popover: UPopoverDirective,
    rowProp: RouteDailyRowProp,
    request: Observable<(RoutesRidePassengersCustomer | RoutesRidePassengerDetails | RideDriverApproval)[]>
  ) {
    if (row[rowProp]) {
      this.popoversStore[rowProp] = row[rowProp];

      popover.open();

      return;
    }

    request
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        (row[rowProp] as (RoutesRidePassengersCustomer | RoutesRidePassengerDetails | RideDriverApproval)[]) = data;

        this.popoversStore[rowProp] = data;

        popover.open();
      });
  }

  onCustomersListPopoverOpen(event: MouseEvent, row: RouteDailyRow, popover: UPopoverDirective) {
    event.stopPropagation();

    this.onPopoverOpen(row, popover, RouteDailyRowProp.PassengersCustomers, this.routesTableService.getRidePassengersCustomers(row.rideId));
  }

  onPassengersListPopoverOpen(event: MouseEvent, row: RouteDailyRow, popover: UPopoverDirective) {
    event.stopPropagation();

    this.track('Click on show passengers list');

    this.onPopoverOpen(row, popover, RouteDailyRowProp.PassengerDetails, this.routesTableService.getRidePassengerDetails(row.rideId));
  }

  onDriversListPopoverOpen(event: MouseEvent, row: RouteDailyRow, popover: UPopoverDirective) {
    event.stopPropagation();

    this.track('Click on show drivers approvals list');

    this.onPopoverOpen(row, popover, RouteDailyRowProp.Drivers, this.rideApprovalsService.getRideDriverApprovals(row.rideId));
  }

  openLockedByTooltip(route: RouteDailyRow, tooltip: UTooltipDirective) {
    if (tooltip.isOpen()) {
      return;
    }

    this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

    if (route.lockState) {
      this.routeLockState = route.lockState;

      tooltip.open();

      return;
    }

    this.routeLockStateService.getRouteLockState(route.routeId)
      .subscribe(
        routeLockState => {
          if (this.lockedByTooltipIdsClosed.some(tooltipId => tooltipId === tooltip.uTooltipWindowId)) {
            this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

            return;
          }

          this.routes = this.routes.map(obj => obj.routeId === route.routeId ? { ...obj, lockState: routeLockState } : obj);
          this.routeLockState = routeLockState;

          tooltip.open();
        }
      );
  }

  closeLockedByTooltip(tooltip: UTooltipDirective) {
    tooltip.close();

    this.lockedByTooltipIdsClosed.push(tooltip.uTooltipWindowId);
  }

  onKpiCollapseToggle(chip: UChip) {
    this.trackingService.track(`[Route Table - KPI] - click to ${chip.collapsed ? 'close' : ' open'}`);
  }

  showRouteNotes(route: RouteDailyRow) {
    this.openRouteNotes.emit(route);
    this.trackingService.track(`[${routesConfig.trackingId} - Notes] - click on note icon`);
  }

  openTimeTrackingChangesPopover(popoverTimeTracking: UPopoverDirective, trackingName: string, data: RouteDailyRow) {
    this.timeTrackingPopover = { popover: popoverTimeTracking, trackingName, data };
    this.track(`click on ${trackingName}`);
  }

  editTimeTracking() {
    this.timeTrackingPopover.popover.close();
    this.trackingService.track(`${routesConfig.trackingId} - ${this.timeTrackingPopover.trackingName} - click on edit time report`);

    this.onCellClick(this.timeTrackingPopover.data, RoutesChangeViewType.TimeTracking, this.timeTrackingChangesPopover);
    this.changesPopover.open();
  }

  resetTimeTracking() {
    this.timeTrackingPopover.popover.close();
    this.trackingService.track(`${routesConfig.trackingId} - ${this.timeTrackingPopover.trackingName} - click on reset time report`);

    this.uPopupService.showMessage(
      {
        no: this.config.dictionary.no,
        yes: this.config.dictionary.reset,
        message: this.config.dictionary.resetTimeTrackingConfirm
      },
      () => {
        this.routesTableService.changeTimeTrack({
          routeId: this.timeTrackingPopover.data.routeId,
          activeDate: this.headerDataService.getActiveDate(),
          value: {
            rideId: this.timeTrackingPopover.data.rideId
          }
        })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => this.routesCommonService.updateChangedRoute());
      },
      () => {}
    );
  }

  onEditValuesClose(data) {
    switch (data.prop) {
      case 'bidNumber': {
        this.routesTableService.changeBidNumber({
          routeId: data.row.routeId,
          activeDate: this.headerDataService.getActiveDate(),
          value: {
            bidNumber: data.newValue
          },
          generateEditableEmail: false,
          sendBackgroundEmail: false
        })
          .pipe(
            take(1),
            takeUntil(this.unsubscribe)
          )
          .subscribe();

        break;
      }
    }
  }

  updateVisibleColumns(columns) {
    this.visibleColumns = columns;
  }

  removeSpecificRowClassObjectsByClassName(classNames: string[]) {
    this.updateSpecificRowClassObjects(
      this.specificRowClassObjects.filter(obj => !classNames.includes(obj.className))
    );
  }

  onDropListEntered(event: CdkDragEnter, row: RouteDailyRow) {
    const routeValid = this.routesDailyMovePassengersDataService.checkPassengerRoutesValidity(event, row);

    this.removeSpecificRowClassObjectsByClassName([ gridConfig.rowValidityClasses.valid, gridConfig.rowValidityClasses.invalid ]);
    this.updateSpecificRowClassObjects([ ...this.specificRowClassObjects, {
      className: routeValid ? gridConfig.rowValidityClasses.valid : gridConfig.rowValidityClasses.invalid,
      rowPropertyName: 'routeId',
      value: row.routeId
    } ]);

    this.cdRef.detectChanges();
  }

  onDropListDropped(event: CdkDragDrop<any>, row: RouteDailyRow) {
    this.removeSpecificRowClassObjectsByClassName([ gridConfig.rowValidityClasses.valid, gridConfig.rowValidityClasses.invalid ]);

    this.routesDailyMovePassengersDataService.onDropListDropped(event, row);
  }
}
