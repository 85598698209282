import { BuilderActiveRideTollRoadsCostType } from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.tuningCost';

export const builderTuningCostComponentConfig = {
  dictionary: {
    totalCost: `${DICTIONARY_PATH}.totalCost`,
    ridePrice: `${DICTIONARY_PATH}.ridePrice`,
    tollRoads: `${DICTIONARY_PATH}.tollRoads`,
    extraFee: `${DICTIONARY_PATH}.extraFee`,
    extraFeeInfo: `${DICTIONARY_PATH}.extraFeeInfo`
  },
  tollRoadsCostTypesItems: [
    { value: BuilderActiveRideTollRoadsCostType.ByContract, name: `${DICTIONARY_PATH}.tollRoadsCostTypes.byContract` },
    { value: BuilderActiveRideTollRoadsCostType.Manual, name: `${DICTIONARY_PATH}.tollRoadsCostTypes.manual` }
  ]
};
