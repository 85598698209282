const DICTIONARY_PATH: string = 'builder.agenda';

export const builderAgendaComponentConfig = {
  dictionary: {
    cancelled: `${DICTIONARY_PATH}.canceled`,
    duplicate: `${DICTIONARY_PATH}.duplicate`,
    ride: {
      startTime: `${DICTIONARY_PATH}.ride.startTime`,
      endTime: `${DICTIONARY_PATH}.ride.endTime`,
      sc: {
        name: `${DICTIONARY_PATH}.ride.sc.name`,
        costCalc: `${DICTIONARY_PATH}.ride.sc.costCalc`,
        rideCost: `${DICTIONARY_PATH}.ride.sc.rideCost`,
        carType: `${DICTIONARY_PATH}.ride.sc.carType`,
        carNo: `${DICTIONARY_PATH}.ride.sc.carNo`,
        carDriver: `${DICTIONARY_PATH}.ride.sc.carDriver`,
        driverHours: `${DICTIONARY_PATH}.ride.sc.driverHours`,
        supervisor: `${DICTIONARY_PATH}.ride.sc.supervisor`,
        commander: `${DICTIONARY_PATH}.ride.sc.commander`,
        contract: `${DICTIONARY_PATH}.ride.sc.contract`,
        hashcalRideType: `${DICTIONARY_PATH}.ride.sc.hashcalRideType`,
        totalCost: `${DICTIONARY_PATH}.ride.sc.totalCost`
      },
      acc: {
        name: `${DICTIONARY_PATH}.ride.acc.name`,
        costCalc: `${DICTIONARY_PATH}.ride.acc.costCalc`,
        hours: `${DICTIONARY_PATH}.ride.acc.hours`,
        cost: `${DICTIONARY_PATH}.ride.acc.cost`,
        totalCost: `${DICTIONARY_PATH}.ride.acc.totalCost`
      }
    },
    required: 'builder.options.accompanies.required',
    select: 'general.select',
    search: 'general.search',
    removeSelect: 'general.removeSelect',
    uInputDate: 'uInputDate',
    uSelectS: 'uSelectS'
  }
};
