<div
  #weeklyViewTable
  appWindowResize
  class="routes-weekly__container"
  (windowResize)="windowResize($event)"
>
  <div class="routes-weekly__backdrop" *ngIf="showBackdrop"></div>
  <u-grid
    #grid
    class="u-grid_app-routes-weekly"
    [ngClass]="{ 'u-grid_app-routes-weekly-has-required-recalculation-rides': requiredRecalculationRides }"
    [tableName]="tableName"
    [saveTableProps]="true"
    [saveColumnsFilters]="true"
    [resetColumnsFilter]="resetColumnsFilter"
    [columns]="columns"
    [rows]="routes"
    [rowClassObjects]="specificRowClassObjects"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="10"
    [footerHeight]="0"
    [rowHeight]="55"
    [reorderable]="true"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [isRtl]="isRtl"
    [lang]="lang"
    [selected]="selectedRows"
    [selectionType]="'checkbox'"
    [messagesTable]="config().dictionary.messagesTable"
    [showColumnsFilter]="true"
    [messages]="'uGrid' | translate"
    [disabledRowProp]="'locked'"
    [rowIdentity]="rowIdentity"
    (sort)="onSortTable($event)"
    (selectRows)="selectRoutes($event)"
    (filterRows)="onFilterRows($event)"
    (resetColumnsFilterChange)="resetColumnsFilterChange($event)"
    (columnsFilteredChange)="columnsFilteredChange($event)"
    (editBtnClickAction)="editRoute($event)"
    (scrollTable)="onScrollTable($event)"
    (selectDotsItemAction)="selectDotsItemAction($event)"
  ></u-grid>
</div>

<ng-template #activitiesColumn ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    *ngIf="!row.locked && !row.saveStatus"
    (click)="openRouteActivities(row.id)"
    class="routes-weekly__activities"
    [ngClass]="{ 'routes-weekly__activities_disabled': !value.length }"
  >
    <span class="routes-weekly__activities-icon"></span>
    <span class="routes-weekly__activities-counter" *ngIf="value | activitiesCounter">{{value | activitiesCounter}}</span>
  </div>
  <div
    class="routes-weekly__loader"
    *ngIf="row.locked && !row.saveStatus"
    #lockedByTooltip="uTooltip"
    [uTooltip]="lockedBy"
    [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
    [tooltipClass]="'u-tooltip_general'"
    [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    [container]="'body'"
    [triggers]="'manual'"
    (mouseenter)="openLockedByTooltip(row, lockedByTooltip)"
    (mouseleave)="closeLockedByTooltip(lockedByTooltip)"
  >
    <div class="loader loader_circle loader_all-events"></div>
    <ng-template #lockedBy>
      <div class="route-lock-state-clock">
        <div class="route-lock-state-clock__text">{{'general.doneBy' | translate}} {{routeLockState.lockedByUser}}</div>
        <div class="route-lock-state-clock__text">
          {{routeLockState.lockDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
        </div>
      </div>
    </ng-template>
  </div>
  <div
    class="routes-weekly__save-status"
    *ngIf="row.saveStatus"
    [ngClass]="{
      'routes-weekly__save-status_successful': row.saveStatus === routeSaveStatus.Successful,
      'routes-weekly__save-status_failed': row.saveStatus === routeSaveStatus.Failed
    }"
  ></div>
</ng-template>

<ng-template
  #dayColumnHeader
  ngx-datatable-header-template
  let-column="column"
  let-ride="value"
>
  <span
    class="routes-weekly__column-day-header"
    [ngClass]="{ 'routes-weekly__column-day-header_active': column.active }"
    (click)="dayHeaderClick(column, ride)"
  >
    {{'routes.weekly.tableWeekDays.' + [column.index] + '' | translate}} <span class="routes-weekly__column-day-number"> {{column.date | date: 'd.MM'}} </span>
  </span>
</ng-template>

<ng-template #codeRoute ngx-datatable-cell-template let-row="row" let-value="value">
  <ng-template #changes>
    <app-routes-change
      [activeRoute]="row"
      [type]="changesType"
      [viewTypeMode]="viewTypeMode"
      [viewportElement]="viewportElement"
      [authCustomer]="authCustomer"
      (action)="closeRoutesChange($event)"
    ></app-routes-change>
  </ng-template>

  <div class="routes-weekly-code">
    <div class="routes-weekly-code__wrapper">
      <span class="routes-weekly-code__value">{{value}}</span>
      <div
        #popoverCanceled="uPopover"
        class="routes-weekly__cancel-popover"
        [popoverId]="row.id"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left-bottom', 'right-bottom', 'right-top', 'right', 'bottom' ] : [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left-bottom', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
      ></div>
    </div>

    <div
      *ngIf="row | routesWeeklyHasRequiredRecalculationRides"
      class="routes-weekly-code__warning-icon-wrapper"
    >
      <div
        class="routes-weekly-code__warning-icon"
        [uTooltip]="requiredRecalculation"
        [tooltipClass]="'u-tooltip_app-routes-required-recalculation'"
        [container]="'body'"
        [placement]="[ 'top' ]"
      ></div>
    </div>
  </div>

  <ng-template #requiredRecalculation>
    <div class="routes-weekly-required-recalculation">
      <div class="routes-weekly-required-recalculation__text">
        {{config().dictionary.requiredRecalculation.message | translate}}
      </div>
      <div class="routes-weekly-required-recalculation__text">
        {{config().dictionary.requiredRecalculation.openEditorAndSave | translate}}
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #weeklyPopover>
  <u-weekly-changes
    [items]="activeChangeItems"
    [showEmail]="showEmail"
    [messages]="'uWeeklyChanges' | translate"
    [placeholder]="'routes.weekly.table.search' | translate"
    [empty]="true"
    [emptyName]="'general.removeSelect' | translate"
    [emptyValue]="-1"
    (actions)="weeklyChangesActions($event)"
  ></u-weekly-changes>
</ng-template>

<ng-template #directionColumn ngx-datatable-cell-template let-row="row">
  <div class="routes-weekly__column">
    <span
      *ngIf="row.direction === direction.Forward"
      class="routes-weekly__column-route-direction"
    >
      {{'general.direction.forward' | translate}}
    </span>
    <span
      *ngIf="row.direction === direction.Backward"
      class="routes-weekly__column-route-direction"
    >
      {{'general.direction.backward' | translate}}
    </span>
  </div>
</ng-template>
<ng-template #directionFilter ngx-datatable-header-filter-template let-item="item">
  <div class="routes-weekly__filter-direction">
    <span
      class="routes-weekly__column-route-direction"
      *ngIf="item === direction.Forward"
      title="{{'general.direction.forward' | translate}}"
    >
      {{'general.direction.forward' | translate}}
    </span>
    <span
      class="routes-weekly__column-route-direction routes-weekly__column-route-direction_back"
      *ngIf="item === direction.Backward"
      title="{{'general.direction.backward' | translate}}"
    >
      {{'general.direction.backward' | translate}}
    </span>
  </div>
</ng-template>

<ng-template #startTime ngx-datatable-cell-template let-row="row">
  <span class="routes-weekly__column-start-time">
    {{row.startDateTime | date: appConstants.TIME_FORMAT}}
  </span>
</ng-template>

<ng-template #endTime ngx-datatable-cell-template let-row="row">
  <span class="routes-weekly__column-end-time">
    {{row.endDateTime | date: appConstants.TIME_FORMAT}}
  </span>
</ng-template>

<ng-template #hourColumnFilter ngx-datatable-header-filter-template let-item="item">
  <span
    class="routes-weekly__column-filter"
    title="{{item ? (item | date: appConstants.TIME_FORMAT) : ('uGrid.emptyValue' | translate)}}"
  >
    {{item ? (item | date: appConstants.TIME_FORMAT) : ('uGrid.emptyValue' | translate)}}
  </span>
</ng-template>

<ng-template
  #dayColumn
  ngx-datatable-cell-template
  let-row="row"
  let-ride="value"
>
  <div
    *ngIf="ride && !ride.isCancelled"
    class="routes-weekly__column"
  >
    <div
      *ngIf="ride.isSelfShuttleCompany"
      class="routes-weekly__column-day-wrapper"
      [ngClass]="{ 'routes-weekly__column-day-wrapper_disabled-change': isSCCustomer && tableAssignmentType === 'accompanyName' && !authDataSnapshotService.editRoutes() || !authDataSnapshotService.editRoutes() }"
      [uPopover]="weeklyPopover"
      #popoverDriver="uPopover"
      [container]="'body'"
      [placement]="isRtl ? [ 'bottom-left', 'bottom', 'bottom-right', 'top-left' ] : [ 'bottom-right', 'bottom', 'bottom-left', 'top-right' ]"
      [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
      [autoClose]="'outside'"
      [triggers]="'click'"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-weekly-changes'"
      (click)="openWeeklyPopover(popoverDriver, ride, row)"
    >
      <span
        *ngIf="tableAssignmentType === 'accompanyName'"
        class="routes-weekly__column-day-assignment"
        [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.accompany }"
        title="{{ride.accompany ? ride.accompany.name : 'routes.weekly.table.accompanyRequired' | translate}}"
      >
        {{ride.accompany ? ride.accompany.name : 'routes.weekly.table.accompanyRequired' | translate}}
      </span>
      <span
        *ngIf="tableAssignmentType === 'driver'"
        class="routes-weekly__column-day-assignment"
        [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.driver }"
        title="{{ride.driver ? ride.driver.name : 'routes.weekly.table.driverRequired' | translate}}"
      >
        {{ride.driver ? ride.driver.name : 'routes.weekly.table.driverRequired' | translate}}
      </span>
    </div>
    <div
      *ngIf="!ride.isSelfShuttleCompany && tableAssignmentType === 'accompanyName'"
      class="routes-weekly__column-day-wrapper"
      [ngClass]="{ 'routes-weekly__column-day-wrapper_disabled-change': isSCCustomer && tableAssignmentType === 'accompanyName' && !authDataSnapshotService.editRoutes() || !authDataSnapshotService.editRoutes() }"
      [uPopover]="weeklyPopover"
      #popoverAccompany="uPopover"
      [container]="'body'"
      [placement]="isRtl ? [ 'bottom-left', 'bottom', 'bottom-right', 'top-left' ] : [ 'bottom-right', 'bottom', 'bottom-left', 'top-right' ]"
      [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
      [autoClose]="'outside'"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-weekly-changes'"
      (click)="openWeeklyPopover(popoverAccompany, ride, row)"
    >
      <span
        class="routes-weekly__column-day-assignment"
        [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.accompany }"
        title="{{ride.accompany ? ride.accompany.name : 'routes.weekly.table.accompanyRequired' | translate}}"
      >
        {{ride.accompany ? ride.accompany.name : 'routes.weekly.table.accompanyRequired' | translate}}
      </span>
    </div>
    <div
      *ngIf="!ride.isSelfShuttleCompany && tableAssignmentType === 'driver'"
      class="routes-weekly__column-day-wrapper routes-weekly__column-day-wrapper_disable"
    >
      <span
        *ngIf="ride.shuttleCompany"
        class="routes-weekly__column-day-assignment"
        title="{{ride.shuttleCompany.name}}"
      >
        {{ride.shuttleCompany.name}}
      </span>
    </div>
  </div>
  <div *ngIf="!ride"
    class="routes-weekly__column"
  >
    <span
      class="routes-weekly__column-day-assignment routes-weekly__column-day-assignment_no-ride"
      title="{{'routes.weekly.table.inactive' | translate}}"
    >
      {{'routes.weekly.table.inactive' | translate}}
    </span>
  </div>

  <div *ngIf="ride && ride.isCancelled"
    class="routes-weekly__column"
  >
    <span
      class="routes-weekly__column-day-assignment routes-weekly__column-day-assignment_ride-cancel"
      title="{{'routes.weekly.table.canceled' | translate}}"

    >
      {{'routes.weekly.table.canceled' | translate}}
    </span>
  </div>
</ng-template>

