import { Component, OnInit, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';

import {
  RoutesChangeViewType,
  RoutesViewTypeMode,
  RouteDailyRow,
  RouteWeeklyRow,
  RoutesChangeDataAction,
  RoutesChangeOptions
} from '@app/routes/models';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { RouteShuttleCompaniesService } from '@app/route-shuttle-companies/services';

@Component({
  selector: 'app-routes-change',
  templateUrl: './routes-change.component.html',
  styleUrls: [ './routes-change.component.scss', './routes-change.component.rtl.scss' ]
})
export class RoutesChangeComponent implements OnInit {
  @Input() viewportElement: HTMLElement;
  @Input() type: RoutesChangeViewType;
  @Input() activeRoute: RouteDailyRow | RouteWeeklyRow;
  @Input() changeOptions: RoutesChangeOptions;
  @Input() viewTypeMode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView = RoutesViewTypeMode.DailyView;
  @Input() authCustomer: AuthCustomer;

  @Output() action: EventEmitter<RoutesChangeDataAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-change';

  actions: RoutesChangeDataAction;
  routesChangeViewType = RoutesChangeViewType;
  isSCCustomer: boolean;
  activeDays: string[];

  constructor(
    private routeShuttleCompaniesService: RouteShuttleCompaniesService
  ) {}

  ngOnInit() {
    this.setCustomerType();
    this.getRouteShuttleCompaniesDates();

    this.actions = {
      close: true,
      save: false,
      routeId: null,
      changedRoute: null
    };
  }

  private setCustomerType() {
    this.isSCCustomer = this.authCustomer && this.authCustomer.type === AuthCustomerType.ShuttleCompany;
  }

  private getRouteShuttleCompaniesDates() {
    if (this.isSCCustomer) {
      this.routeShuttleCompaniesService.getRouteShuttleCompaniesDates('id' in this.activeRoute ? this.activeRoute.id : this.activeRoute.routeId)
        .pipe(take(1))
        .subscribe(data => this.activeDays = data.dates);
    }
  }

  close(action: RoutesChangeDataAction) {
    if (action) {
      this.action.emit(action);
    }
  }
}
