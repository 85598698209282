import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { isEqual } from 'lodash';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UButtonSize, URangePreset, USelectSItem } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import {
  Errors,
  DaysOfWeek
} from '@app/shared/models';
import {
  CommonService,
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { RouteChangeValidationService, RoutesChangeDataService, RoutesTableService } from '@app/routes/services';
import {
  RoutesChangeEmailSendType,
  RoutesViewTypeMode,
  RouteDaily,
  RoutesChangeCarTypeConfirmAction,
  RoutesChangeCarTypeBody,
  RoutesChangeType
} from '@app/routes/models';
import { routesConfig } from '@app/routes/configs';
import { routesChangeCarComponentConfig } from './routes-change-car.component.config';

@Component({
  selector: 'app-routes-change-car',
  templateUrl: './routes-change-car.component.html',
  styleUrls: [ './routes-change-car.component.scss', './routes-change-car.component.rtl.scss' ]
})
export class RoutesChangeCarComponent implements OnInit, OnDestroy {
  @Input() activeRoute: RouteDaily;
  @Input() viewportElement: HTMLElement;
  @Input() viewTypeMode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView = RoutesViewTypeMode.DailyView;

  @HostBinding('class') hostClasses: string = 'routes-change-car';

  private unsubscribe: Subject<void> = new Subject();

  carTypeItems: USelectSItem[];
  config = cloneDeep(routesChangeCarComponentConfig);
  form: UntypedFormGroup = this.fb.group({});
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  uButtonSize = UButtonSize;

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private routesTableService: RoutesTableService,
    private headerDataService: HeaderDataService,
    private trackingService: TrackingService,
    private routeChangeValidationService: RouteChangeValidationService,
    private routesChangeDataService: RoutesChangeDataService
  ) {}

  ngOnInit() {
    this.createForm();
    this.onCarTypeChange();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getCarTypes(dateFrom: string, dateTo: string, days: DaysOfWeek[]) {
    this.routesTableService.getCarTypes({
      routeId: this.activeRoute.routeId,
      dateFrom,
      dateTo,
      days
    })
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(carTypes => {
        this.carTypeItems = carTypes.map(car => ({ name: car.name, value: car.id }));

        const isCarTypeExist = this.carTypeItems.some(carTypeItem => carTypeItem.value === this.form.get('carType').value);

        if (!isCarTypeExist) {
          this.form.get('carType').patchValue(null);
        }
      });
  }

  private createForm() {
    this.form = this.fb.group({
      routeId: [ this.activeRoute.routeId ],
      datesChange: this.fb.group({
        dates: [ [ this.headerDataService.getActiveDate() ] ],
        dateFrom: [ ],
        dateTo: [ ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.config.availablePresets ],
        checkDaysActive: [ [ moment(this.headerDataService.getActiveDate()).weekday() ] ],
        checkDaysAvailable: [ this.config.checkDaysAvailable ]
      }),
      type: [ RoutesChangeType.Planned, Validators.required ],
      carType: [ this.activeRoute.carType && this.activeRoute.carType.value, Validators.required ],
      comment: [ '' ]
    });

    this.updateCheckDays();
  }

  private updateCheckDays() {
    const activeDate = this.headerDataService.getActiveDate();
    const activeDays: DaysOfWeek[] = [ moment(activeDate).get('day') ];

    this.form.get('datesChange').patchValue({
      dates: [ activeDate ],
      dateFrom: this.viewTypeMode === RoutesViewTypeMode.DailyView ? this.activeRoute['routeStartDate'] : this.activeRoute['startDate'],
      dateTo: this.viewTypeMode === RoutesViewTypeMode.DailyView ? this.activeRoute['routeEndDate'] : this.activeRoute['endDate'],
      checkDaysActive: [ ...activeDays ],
      checkDaysAvailable: this.activeRoute['days']
    });

    this.getCarTypes(activeDate, activeDate, activeDays);
  }

  updatePeriod(data) {
    this.form.get('datesChange').patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });

    this.getCarTypes(data.dates[0], data.dates[data.dates.length - 1], data.checkDaysActive);
  }

  updateDates({ dates, checkDaysActive }) {
    const datesStore: string[] = this.form.get('datesChange.dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.form.get('datesChange').patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    this.trackingService.track(`${routesConfig.trackingId} - planned car type - edit dialog - click on save`);

    const formValue = this.form.getRawValue();
    const body: RoutesChangeCarTypeBody = {
      activeDate: this.headerDataService.getActiveDate(),
      routeId: this.activeRoute.routeId,
      value: {
        comment: formValue.comment,
        dateFrom: moment(formValue.datesChange.dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_BASE_LINE),
        dateTo: moment(formValue.datesChange.dates[formValue.datesChange.dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_BASE_LINE),
        days: formValue.datesChange.checkDaysActive,
        type: formValue.type,
        carTypeId: formValue.carType
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routeChangeValidationService.validationCarType(body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        () => this.changeCarType(body),
        error => {
          if (error.code === Errors.CarTypeExceedsVehicleCapacity) {
            this.routesChangeDataService.changeCarTypeConfirm(error.description, routesConfig.trackingId)
              .pipe(
                takeUntil(this.unsubscribe),
                take(1),
                tap(action => {
                  if (action === RoutesChangeCarTypeConfirmAction.Ok) {
                    this.changeCarType({
                      ...body,
                      value: {
                        ...body.value,
                        allowExceedVehicleCapacity: true
                      }
                    });
                  }
                })
              ).subscribe();
          }
        }
      );
  }

  changeCarType(body) {
    this.routesTableService.changeCarType(body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.commonService.updateChangedRouteData({
          close: true,
          save: false,
          changedRoute: null,
          routeId: this.activeRoute.routeId
        });
      });
  }

  onCarTypeChange() {
    this.form.get('carType').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.trackingService.track(`${ routesConfig.trackingId } - click on value`));
  }
}
