import {
  Component,
  HostBinding,
  inject,
  output,
  signal
} from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { NgxMaskModule } from 'ngx-mask';
import { UCommonModule, UIdModule, UInputDirective, UTooltipModule } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { BuilderDataService, BuilderTuningStoreService } from '@app/builder/services';
import { builderTuningCostComponentConfig } from './builder-tuning-cost.component.config';

@Component({
  selector: 'app-builder-tuning-cost',
  templateUrl: './builder-tuning-cost.component.html',
  styleUrls: [ './builder-tuning-cost.component.scss', './builder-tuning-cost.component.rtl.scss' ],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TranslateModule,
    UInputDirective,
    UIdModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    UTooltipModule,
    NgxMaskModule,
    UCommonModule
  ]
})
export class BuilderTuningCostComponent {
  readonly formControlClick = output<void>();

  @HostBinding('class') hostClasses: string = 'builder-tuning-cost';

  private readonly localizationService = inject(LocalizationService);
  public readonly builderDataService = inject(BuilderDataService);
  public readonly builderTuningStoreService = inject(BuilderTuningStoreService);

  readonly #config = signal(cloneDeep(builderTuningCostComponentConfig));
  readonly #showCostFields = signal(false);

  readonly config = this.#config.asReadonly();
  readonly showCostFields = this.#showCostFields.asReadonly();

  readonly isRtl = this.localizationService.isRtl();

  showCostFieldsToggle() {
    this.#showCostFields.set(!this.showCostFields());
  }
}
