<div class="routes__container">
  <ng-container *ngIf="{
    showMovePassengers: commonService.showMovePassengers$ | async,
    showFeed: commonService.showFeed$ | async,
    showReOptimization: commonService.showReOptimization$ | async
  } as obsObj">
    <div
      class="routes__grid-header"
      [ngClass]="{ 
        'routes__grid-header_feed': obsObj.showFeed
      }"
    >
      <div
        *ngIf="!obsObj.showReOptimization"
        class="routes__grid-header-filters"
        [ngClass]="{
          'routes__grid-header-filters_panel-opened': obsObj.showMovePassengers
        }"
      >
        <div class="routes__grid-header-filter" *ngIf="config?.dailyFilters && selectedViewType?.value === routesViewType.Daily">
          <u-droplist
            class="u-droplist_app-routes-grid-header-filter"
            [items]="config.dailyFilters"
            [selected]="selectedDailyFilter"
            (changeSelected)="changeRoutesDailyFilterType($event)"
          ></u-droplist>
        </div>
        <div class="routes__grid-header-filter" *ngIf="config?.weeklyFilters && selectedViewType?.value === routesViewType.Weekly">
          <u-droplist
            *ngIf="showAccompany"
            class="u-droplist_app-routes-grid-header-filter"
            [items]="config.weeklyFilters"
            [selected]="selectedWeeklyFilter"
            (changeSelected)="weekViewTypeChanged($event)"
          ></u-droplist>
          <div
            *ngIf="!showAccompany"
            class="routes__grid-header-filter-name"
          >
            {{selectedWeeklyFilter.name | translate}}
          </div>
        </div>
      </div>
      <app-grid-header
        class="routes__grid-header-main"
        [ngClass]="{
          'routes__grid-header-main_without-buttons': obsObj.showMovePassengers
        }"
        [template]="gridHeaderTemplate"
        [filteredColumns]="columnsFiltered"
        [selected]="checkedRoutes ? checkedRoutes.length : 0"
        [total]="visibleRoutesAmount"
        [showButtons]="!obsObj.showMovePassengers"
        (buttonClick)="onGridHeaderButtonClick($event)"
      ></app-grid-header>
    </div>
    <u-split
      [useTransition]="true"
      [dir]="isRtl ? 'rtl' : 'ltr'"
      [disabled]="true"
      class="routes__container-split"
      direction="horizontal"
    >
      <u-split-area
        *ngIf="obsObj.showMovePassengers"
        class="u-split-area-layout"
        [order]="0"
        [panelWidth]="296"
      >
        <app-routes-daily-move-passengers
          (closeAction)="commonService.updateVisibleComponent(visibleComponent.MovePassengers, false)"
        ></app-routes-daily-move-passengers>
      </u-split-area>
      <u-split-area
        *ngIf="obsObj.showFeed"
        class="u-split-area-layout u-split-area-layout_app-feed"
        [order]="0"
        [panelWidth]="252"
      >
        <app-feed
          [trackingId]="routesConfig.feedTrackingId"
        ></app-feed>
      </u-split-area>
      <u-split-area
        *ngIf="obsObj.showReOptimization"
        class="u-split-area-layout u-split-area-layout_app-re-optimization-summary"
        [order]="0"
        [panelWidth]="252"
      >
        <app-re-optimization-summary
          (closeSummary)="closeReOptimizationSummary()"
          (selectSession)="onSelectReOptimizationSession($event)"
        ></app-re-optimization-summary>
      </u-split-area>
      <u-split-area
        class="u-split-area-layout"
        *ngIf="notesRouteData$ | async as notesRouteData"
        [order]="1"
        [panelWidth]="252"
      >
        <app-notes-list
          [data]="notesRouteData.notes"
          [trackingId]="trackingId"
          [authUser]="authUser"
          [subTitle]="notesRouteData.route.code + ' ' + notesRouteData.route.name"
          (closeNotes)="markNotesAsRead(true)"
          (editNote)="editRouteNote($event)"
          (readNotes)="routeNotesRead($event)"
          (removeNote)="removeRouteNote($event)"
          (newNote)="createNewRouteNote($event)"
          (clickOutside)="markNotesAsRead()"
        ></app-notes-list>
      </u-split-area>
      <u-split-area
        *ngIf="showNextYearPanel"
        class="u-split-area-layout"
        [order]="1"
        [panelWidth]="252"
      >
        <app-next-year
          (closeAction)="closeNextYearPanel()"
        ></app-next-year>
      </u-split-area>
      <u-split-area
        [order]="2"
        [ngClass]="{
          'u-split-area-layout': !obsObj.showReOptimization,
          'u-split-area-layout_app-default': !obsObj.showReOptimization,
          'u-split-area-layout_app-routes-optimization': obsObj.showReOptimization
        }"
      >
        <app-routes-daily
          *ngIf="selectedViewType?.value === routesViewType.Daily && initialData"
          [refreshTableData]="refreshTableData"
          [highlightRoutes]="highlightRoutes"
          [closeAllPopovers]="closeAllPopovers"
          [clearDailyCheckedItems]="clearDailyCheckedItems"
          [feedFilter]="feedFilter"
          [tableFilterType]="selectedDailyFilter"
          [resetColumnsFilter]="resetColumnsFilter"
          [showMovePassengers]="obsObj.showMovePassengers"
          [showReOptimization]="obsObj.showReOptimization"
          [reOptimizationRouteIds]="reOptimizationRouteIds"
          [initialData]="initialData"
          (resetColumnsFilterAction)="resetColumnsFilterChange($event)"
          (refresh)="onTableRefresh()"
          (visibleRoutesAmountChanged)="visibleRoutesAmountChanged($event)"
          (checkedRoutes)="dailyRoutesSelect($event)"
          (columnsFilteredAction)="columnsFilteredChange($event)"
          (deleteRouteAction)="deleteRoute($event)"
          (openRouteNotes)="openNotes($event)"
        ></app-routes-daily>
        <app-routes-weekly
          *ngIf="selectedViewType?.value === routesViewType.Weekly && initialData"
          [tableAssignmentType]="tableAssignmentType"
          [refreshTableData]="refreshTableData"
          [highlightRoutes]="highlightRoutes"
          [closeAllPopovers]="closeAllPopovers"
          [feedFilter]="feedFilter"
          [clearWeeklyCheckedItems]="clearWeeklyCheckedItems"
          [resetColumnsFilter]="resetColumnsFilter"
          (checkedRoutes)="weeklyRoutesSelect($event)"
          (visibleRoutesAmountChanged)="visibleRoutesAmountChanged($event)"
          (refresh)="onTableRefresh()"
          (resetColumnsFilterAction)="resetColumnsFilterChange($event)"
          (columnsFilteredAction)="columnsFilteredChange($event)"
          (deleteRouteAction)="deleteRoute($event)"
        ></app-routes-weekly>

        <app-re-optimization-changes
          *ngIf="obsObj.showReOptimization"
          class="routes__re-optimization-changes"
          [sessionId]="reOptimizationSessionId"
          (loadRouteChanges)="onLoadReOptimizationChanges($event)"
        ></app-re-optimization-changes>
      </u-split-area>
    </u-split>
  </ng-container>
</div>
